import React from "react";
import styled from "styled-components";

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 5px;
  border-radius: 10px;
  background-color: #990000;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
`;

interface BadgeProps {
  count: number;
  style?: React.CSSProperties;
}

const Badge: React.FC<BadgeProps> = ({ count, style }) => {
  return <BadgeContainer style={style}>{count}</BadgeContainer>;
};

export default Badge;
