import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import Copy from "@assets/icons/duplicate.svg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CopyBox = ({ title, code }) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          toast.success(t("success.copied"));
        })
        .catch((err) => {});
    }
  };

  return (
    <div style={{ marginBottom: "24px", marginTop: "20px", padding: "0 12px" }}>
      <Heading
        fontSize="14px"
        title={title}
        fontWeight="400"
        style={{ marginBottom: "8px" }}
        color={theme.colors.white.normal}
      />
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          backgroundColor: theme.colors.grey.dark,
          borderRadius: "8px",
          padding: "8.5px 16px",
        }}
      >
        <Heading
          fontSize="17px"
          title={code}
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <div style={{ cursor: "pointer" }} onClick={handleCopy}>
          <img
            src={Copy}
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      </div>
    </div>
  );
};

export default CopyBox;
