/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useMemo } from "react";
import moment from "moment";
import { useAuth } from "./AuthContext";

// Types
type DateFormat = "date" | "datetime";
type CountryCode = "UK" | "ES" | "US";

interface DefaultValue {
  formatTimestamp(timestamp: number, format?: DateFormat): string;
  country: CountryCode;
}
interface DateFormatConfig {
  dateFormat: string;
  dateTimeFormat: string;
}

// Constants
const COUNTRY_DATE_FORMATS = {
  "DD/MM/YYYY": {
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY - HH:mm",
  },
  "MM/DD/YYYY": {
    dateFormat: "MM/DD/YYYY",
    dateTimeFormat: "MM/DD/YYYY - hh:mm A",
  },
};

const defaultValue: DefaultValue = {
  formatTimestamp: () => "",
  country: "UK",
};

// Context
const SettingsContext = createContext(defaultValue);

export const SettingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // For now, we're keeping the country static
  const country: CountryCode = "ES";
  const { accountSetting } = useAuth();

  const formatTimestamp = (
    timestamp: number,
    format: DateFormat = "date"
  ): string => {
    const timestampInMs = timestamp * 1000;

    const dateConfig =
      COUNTRY_DATE_FORMATS[accountSetting?.dateFormat || "DD/MM/YYYY"];
    console.log("dateConfig", accountSetting?.dateFormat, dateConfig);
    const formatString =
      format === "date" ? dateConfig.dateFormat : dateConfig.dateTimeFormat;

    return moment(timestampInMs).format(formatString);
  };

  const contextValue = useMemo(
    () => ({
      formatTimestamp,
      country,
    }),
    [formatTimestamp, country, accountSetting?.dateFormat]
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
