import React, { createContext, useContext, useState, useCallback } from "react";
import { useGetStats } from "@api/useGetStats";
import { theme } from "@styles/themes";
import { StatsData } from "@api/types";

interface BetsStats {
  totalBets: number;
  winBets: number;
  lostBets: number;
  voidBets: number;
  amountBet: number;
  amountEarned: number;
  averageStake: number;
  averageOdd: number;
  yield: number;
  accounts: number;
  averageProfit: number;
  roi: number;
  totalProfit: number;
}

interface BookieStats {
  bookie: string;
  totalProfit: number;
  color: string;
}

interface SportStats {
  sport: string;
  totalProfit: number;
  color: string;
}

interface FilterState {
  bets: {
    bookies: string[];
    sports: string[];
    profiles: string[];
  };
  bookies: {
    bookies: string[];
    sports: string[];
    profiles: string[];
  };
}

interface TempFilterState {
  bets: {
    bookies: string[];
    sports: string[];
    profiles: string[];
  };
  bookies: {
    bookies: string[];
    sports: string[];
    profiles: string[];
  };
}

export type FilterType = "bets" | "bookies";

interface StatsContextType {
  filters: FilterState;
  tempFilters: TempFilterState;
  setFilter: (
    filterType: "bets" | "bookies",
    filterName: string,
    values: string[]
  ) => void;
  setTempFilter: (
    filterType: "bets" | "bookies",
    filterName: string,
    values: string[]
  ) => void;
  isActiveTemp: (
    filterType: FilterType,
    filterName: string,
    id?: string
  ) => boolean;
  applyTempFilters: () => void;
  clearFilters: (type?: FilterType) => void;
  getBetsStats: (filterType: FilterType) => BetsStats | null;
  getBookieStats: (filterType: FilterType) => {
    stats: BookieStats[];
    totalProfit: number;
  };
  getSportStats: (filterType: FilterType) => {
    stats: SportStats[];
    totalProfit: number;
  };
  isFilterActive: (filterType: FilterType, filterName: string) => boolean;
  applyFilters: (filterType: FilterType) => void;
  getActiveFilters: (filterType: FilterType) => {
    bookies: string[];
    sports: string[];
    profiles: string[];
  };
  statsData: StatsData | null;
  isLoading: boolean;
}

const StatsChartContext = createContext<StatsContextType | undefined>(
  undefined
);

export const StatsChartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [filters, setFilters] = useState<FilterState>({
    bets: {
      bookies: [],
      sports: [],
      profiles: [],
    },
    bookies: {
      bookies: [],
      sports: [],
      profiles: [],
    },
  });

  const [tempFilters, setTempFilters] = useState<TempFilterState>({
    bets: {
      bookies: [],
      sports: [],
      profiles: [],
    },
    bookies: {
      bookies: [],
      sports: [],
      profiles: [],
    },
  });

  const setFilter = (
    filterType: "bets" | "bookies",
    filterName: string,
    values: string[]
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: {
        ...prevFilters[filterType],
        [filterName]: values,
      },
    }));
  };

  const setTempFilter = (
    filterType: "bets" | "bookies",
    filterName: string,
    values: string[]
  ) => {
    setTempFilters((prevTempFilters) => ({
      ...prevTempFilters,
      [filterType]: {
        ...prevTempFilters[filterType],
        [filterName]: values,
      },
    }));
  };

  const applyTempFilters = () => {
    setFilters(tempFilters);
  };

  const clearFilters = (type?: FilterType) => {
    if (type) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [type]: {
          bookies: [],
          sports: [],
          profiles: [],
        },
      }));
    } else {
      setFilters({
        bets: {
          bookies: [],
          sports: [],
          profiles: [],
        },
        bookies: {
          bookies: [],
          sports: [],
          profiles: [],
        },
      });
    }

    setTempFilters({
      bets: {
        bookies: [],
        sports: [],
        profiles: [],
      },
      bookies: {
        bookies: [],
        sports: [],
        profiles: [],
      },
    });
  };

  const { data: statsData, isLoading } = useGetStats();

  const isFilterActive = (filterType: FilterType) => {
    const activeFilters = filters[filterType];
    return Object.values(activeFilters).some((filter) => filter.length > 0);
  };

  const calculateStats = useCallback(
    (filterType: FilterType) => {
      if (!statsData) return null;

      const activeFilters = filters[filterType];
      let accounts = 0;
      let totalBets = 0;
      let winBets = 0;
      let lostBets = 0;
      let voidBets = 0;
      let totalAmount = 0;
      let totalPayout = 0;
      let totalOdds = 0;

      Object.entries(statsData.stats).forEach(([bookie, profiles]) => {
        if (
          activeFilters.bookies.length === 0 ||
          activeFilters.bookies.includes(bookie)
        ) {
          profiles.forEach((profile) => {
            if (
              activeFilters.profiles.length === 0 ||
              activeFilters.profiles.includes(profile.profileId)
            ) {
              accounts++;
              Object.entries(profile.sports).forEach(([sport, stats]) => {
                if (
                  activeFilters.sports.length === 0 ||
                  activeFilters.sports.includes(sport)
                ) {
                  totalBets += stats.numBets;
                  winBets += stats.winBets;
                  lostBets += stats.lostBets;
                  voidBets += stats.voidBets;
                  totalAmount += stats.amount;
                  totalPayout += stats.profits;
                  totalOdds += stats.avgOdd * stats.numBets;
                }
              });
            }
          });
        }
      });

      const averageStake = totalBets > 0 ? totalAmount / totalBets : 0;
      const averageOdd = totalBets > 0 ? totalOdds / totalBets : 0;
      const yieldValue = totalBets > 0 ? (totalPayout / totalBets) * 100 : 0;
      const averageProfit = accounts > 0 ? totalPayout / accounts : 0;
      const roi =
        totalAmount > 0 ? ((totalPayout - totalAmount) / totalAmount) * 100 : 0;

      return {
        totalBets,
        winBets,
        lostBets,
        voidBets,
        amountBet: totalAmount,
        amountEarned: totalPayout - totalAmount,
        averageStake,
        averageOdd,
        yield: yieldValue,
        accounts,
        averageProfit,
        roi,
        totalProfit: totalPayout,
      };
    },
    [statsData, filters]
  );

  const calculateBookieStats = useCallback(
    (filterType: FilterType) => {
      if (!statsData) return { stats: [], totalProfit: 0 };

      const activeFilters = filters[filterType];
      const bookieStats = new Map<string, number>();

      Object.entries(statsData.stats).forEach(([bookie, profiles]) => {
        if (
          activeFilters.bookies.length === 0 ||
          activeFilters.bookies.includes(bookie)
        ) {
          profiles.forEach((profile) => {
            if (
              activeFilters.profiles.length === 0 ||
              activeFilters.profiles.includes(profile.profileId)
            ) {
              Object.entries(profile.sports).forEach(([sport, stats]) => {
                if (
                  activeFilters.sports.length === 0 ||
                  activeFilters.sports.includes(sport)
                ) {
                  bookieStats.set(
                    bookie,
                    (bookieStats.get(bookie) || 0) + stats.profits
                  );
                }
              });
            }
          });
        }
      });

      const sortedStats = Array.from(bookieStats.entries())
        .sort(([, a], [, b]) => b - a)
        .slice(0, 7); // Take top 7 bookies

      const colors = [
        "#FF7300",
        theme.colors.yellow.normal,
        theme.colors.red.normal,
        theme.colors.green.normal,
        "#FF00F7",
        "#00FBFF",
        theme.colors.blue.normal,
        theme.colors.silver,
        theme.colors.alto,
        theme.colors.main,
      ];

      const formattedStats = sortedStats.map(([bookie, profit], index) => ({
        bookie,
        totalProfit: profit,
        color:
          typeof colors[index] === "string" ? colors[index] : theme.colors.alto,
      }));

      const totalProfit = formattedStats.reduce(
        (sum, stat) => sum + stat.totalProfit,
        0
      );

      return {
        stats: formattedStats,
        totalProfit,
      };
    },
    [statsData, filters]
  );

  const calculateSportStats = useCallback(
    (filterType: FilterType) => {
      if (!statsData) return { stats: [], totalProfit: 0 };

      const activeFilters = filters[filterType];
      const sportStats = new Map<string, number>();

      Object.entries(statsData.stats).forEach(([bookie, profiles]) => {
        if (
          activeFilters.bookies.length === 0 ||
          activeFilters.bookies.includes(bookie)
        ) {
          profiles.forEach((profile) => {
            if (
              activeFilters.profiles.length === 0 ||
              activeFilters.profiles.includes(profile.profileId)
            ) {
              Object.entries(profile.sports).forEach(([sport, stats]) => {
                if (
                  activeFilters.sports.length === 0 ||
                  activeFilters.sports.includes(sport)
                ) {
                  sportStats.set(
                    sport,
                    (sportStats.get(sport) || 0) + stats.profits
                  );
                }
              });
            }
          });
        }
      });

      const sortedStats = Array.from(sportStats.entries())
        .sort(([, a], [, b]) => b - a)
        .slice(0, 7); // Take top 7 sports

      const colors = [
        "#FF7300",
        theme.colors.yellow.normal,
        theme.colors.red.normal,
        theme.colors.green.normal,
        "#FF00F7",
        "#00FBFF",
        theme.colors.blue.normal,
      ];

      const formattedStats = sortedStats.map(([sport, profit], index) => ({
        sport,
        totalProfit: profit,
        color: colors[index],
      }));

      const totalProfit = formattedStats.reduce(
        (sum, stat) => sum + stat.totalProfit,
        0
      );

      return {
        stats: formattedStats,
        totalProfit,
      };
    },
    [statsData, filters]
  );

  const isActiveTemp = (
    filterType: FilterType,
    filterName: string,
    id: string
  ) => {
    if (id) {
      return tempFilters[filterType][filterName].includes(id);
    }
    return tempFilters[filterType][filterName].length > 0;
  };

  const getActiveFilters = (filterType: FilterType) => {
    const activeFilters = filters[filterType];
    return {
      bookies: activeFilters.bookies,
      sports: activeFilters.sports,
      profiles: activeFilters.profiles,
    };
  };

  const applyFilters = (filterType: FilterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: tempFilters[filterType],
    }));
  };

  return (
    <StatsChartContext.Provider
      value={{
        filters,
        tempFilters,
        setFilter,
        setTempFilter,
        applyTempFilters,
        clearFilters,
        isFilterActive,
        getBetsStats: calculateStats,
        getBookieStats: calculateBookieStats,
        getSportStats: calculateSportStats,
        isActiveTemp,
        getActiveFilters,
        statsData,
        isLoading,
        applyFilters,
      }}
    >
      {children}
    </StatsChartContext.Provider>
  );
};

export const useStatsChart = () => {
  const context = useContext(StatsChartContext);
  if (context === undefined) {
    throw new Error("useStatsChart must be used within a StatsChartProvider");
  }
  return context;
};
