import { useGetProfiles } from "@api/useGetProfiles";
import Heading from "@components/font/heading";
import { usePayment } from "@context/PaymentContext";
import { TabContext } from "@context/TabContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backBtn from "@assets/icons/arrowLeft.svg";

const ProfileBookieHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;

  const handleCross = () => {
    navigate(-1);
  };
  const { data } = useGetProfiles({});
  const { profileIdSelected } = useContext(TabContext);
  const profileHeadName =
    data?.profiles?.find((elem) => elem.profileId === profileIdSelected)
      ?.profileName || "";

  const bookiesLength = data?.profiles?.find(
    (elem) => elem.profileId == profileIdSelected
  )?.bookies.length;
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        borderBottom: "0.5px solid",
        borderBottomColor: theme.colors.white.dark,
        padding: "14px  20px 14px 20px",
        height: "55px",
      }}
    >
      <div className="d-flex align-items-center">
        <img
          src={backBtn}
          width="24px"
          height="24px"
          className="me-3"
          onClick={() => pathname == "/profile/bookie" ?navigate('/profile'):navigate(-1)}
          style={{ cursor: "pointer", color: theme.colors.white.normal }}
        />
        <Heading
          fontSize="20px"
          title={
            pathname == "/profile/bookie/add/new"
              ? "profile.addBookie"
              : pathname == "/profile/bookie/add/edit"
              ? "profile.editBookie"
              : pathname == "/profile/bookie/record"
              ? "profile.bookieRecord"
              : pathname == "/profile/bookie/archive"
              ? "profile.archieveBookie"
              : `${profileHeadName}`
          }
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>
      {pathname == "/profile/bookie" && (
        <div className="d-flex align-items-center">
          <Icon
            onClick={() => navigate("/profile/bookie/add/new")}
            icon="f7:plus-app"
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
          {bookiesLength > 0 && (
            <Icon
              onClick={() => navigate("/profile/bookie/archive")}
              icon="fluent:archive-16-regular"
              width="24"
              height="24"
              style={{ marginLeft: "16px" }}
              color={theme.colors.white.normal}
            />
          )}
        </div>
      )}
      {(pathname == "/profile/bookie/record" ||
        pathname == "/profile/bookie/archive") && (
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon="charm:cross"
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileBookieHeader;
