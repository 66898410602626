import Heading from "@components/font/heading";
import { TabContext } from "@context/TabContext";
import { theme } from "@styles/themes";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import NotificationCardDesktop from "../notificationSection/notificationCardDesktop";
import { useGetNotifications } from "@api/useGetNotifications";
import { useDeleteAllNotification } from "@api/useDeleteAllNotifications";
import SkeletonLoader from "@components/loader/skeleton";
import NotificationEmpty from "@assets/notifications/emptyNotification.svg";
import { useReadNotification } from "@api/useReadNotification";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";

const NotificationCardContainer = styled.div`
  background-color: ${theme.colors.grey.darker};
  border-radius: 8px;
  position: absolute;
  max-width: 408px;
  width: 408px;
  max-height: 469px;
  overflow: hidden;
  height: 469px;
  z-index: 999;
  right: -70px;
  top: 10px;
  border: 1px solid ${theme.colors.grey.normal};
`;
const ScrollableContainer = styled.div`
  height: 410px;
  overflow-y: auto;
  // margin: 10px 5px;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${theme.colors.grey
      .darker}; /* Dark black background for the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.grey.dark}; /* Scrollbar thumb color (dark black) */
    border-radius: 10px; /* Rounded scrollbar thumb */
  }
`;
const NotificationComponentDesk = () => {
  // variables
  const { notificationCardOpen, setNotificationCardOpen } =
    useContext(TabContext);
  const { data: notificationData, isLoading, refetch } = useGetNotifications();
  const { mutate } = useDeleteAllNotification();
  const { mutate: readMutate } = useReadNotification();

  // effect
  useEffect(() => {
    if (notificationCardOpen) {
      if (notificationData?.notifications?.find(notify => !notify?.isRead)) {
        readMutate("readAll");
      }
    }
  }, [notificationCardOpen]);

  const overlay = (
    <NotificationCardContainer>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: "16px",
          borderBottom: `1px solid ${theme.colors.grey.normal}`,
        }}
      >
        <Heading
          title="profile.notifications"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        {notificationData?.notifications?.length > 0 && (
          <Heading
            title="profile.deleteAll"
            fontSize="14px"
            fontWeight="400"
            onClick={() => mutate("deleteAll")}
            color={theme.colors.white.normal}
            style={{
              textDecoration: "underline",
              textAlign: "right",
              cursor: "pointer",
            }}
          />
        )}
      </div>

      <ScrollableContainer>
        {isLoading ? (
          <SkeletonLoader height="77px" />
        ) : notificationData?.notifications?.length <= 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <img
              src={NotificationEmpty}
              style={{ minWidth: "210px" }}
              alt="Notify Empty"
            />
          </div>
        ) : (
          notificationData?.notifications?.map((notify: any, index: number) => {
            const date = new Date(notify?.date * 1000);
            const formattedDate = date.toLocaleDateString("en-GB");
            return (
              <NotificationCardDesktop
                refetch={refetch}
                notifyId={notify?.notificationId}
                key={index}
                isIncidence={notify?.isIncidence}
                title={notify?.title}
                date={formattedDate}
                content={notify?.description}
                isRead={notify?.isRead}
              />
            );
          })
        )}
      </ScrollableContainer>
    </NotificationCardContainer>
  );

  return (
    <Dropdown
      overlay={overlay}
      trigger={["click"]}
      onVisibleChange={(e) => {
        setNotificationCardOpen(false);
      }}
      visible={notificationCardOpen}
      placement="bottomRight"
      overlayClassName="notification-dropdown"
    >
      <div />
    </Dropdown>
  );
};

export default NotificationComponentDesk;
