import "./translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

// libraries
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { ToastContainer, toast, Slide } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { Tooltip } from "react-tooltip";

// components
import { MainRouter } from "@navigation/MainRouter";

// misc
import { theme } from "./styles/themes";
import { AuthProvider } from "@context/AuthContext";
import { SidebarProvider } from "@context/SidebarContext";
import { TabProvider } from "@context/TabContext";
import { PaymentProvider } from "@context/PaymentContext";
import { SettingsProvider } from "@context/SettingsContext";
import { StatsChartProvider } from "@context/StatsChartContext";
import { FormProvider } from "@context/FormContext";
import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css"; // Custom styles for overrides

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000,
      cacheTime: 5 * 60 * 60 * 1000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

// Create a web storage persistor
// const localStoragePersistor = createWebStoragePersistor({
//   storage: window.localStorage,
//   key: "REACT_QUERY_CACHE", // Cache key in localStorage
// });

// // Configure persistence
// persistQueryClient({
//   queryClient,
//   persistor: localStoragePersistor,
//   maxAge: 24 * 60 * 60 * 1000, // 24 hours
//   buster: process.env.REACT_APP_VERSION || "1.0.0", // Cache buster
//   dehydrateOptions: {
//     shouldDehydrateQuery: (query) => {
//       // Don't persist sensitive data or mutations
//       const nonPersistentQueries = ["login", "refresh"];
//       return !nonPersistentQueries.includes(query.queryKey[0]);
//     },
//   },
// });

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TabProvider>
        <ThemeProvider theme={theme}>
          <SidebarProvider>
            <AuthProvider>
              <PaymentProvider>
                <SettingsProvider>
                  <StatsChartProvider>
                    <FormProvider>
                      <MainRouter />
                      <ToastContainer />
                      <Tooltip id="my-tooltip" />

                      <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar
                        closeOnClick
                        pauseOnHover
                        draggable
                      />
                    </FormProvider>
                  </StatsChartProvider>
                </SettingsProvider>
              </PaymentProvider>
            </AuthProvider>
          </SidebarProvider>
        </ThemeProvider>
      </TabProvider>
    </QueryClientProvider>
  );
};

export default App;
