import useWidth from "@hooks/useWidth";
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { GraphData } from "@api/useGetDashboard";

const GraphDashboard = ({ data }: { data: GraphData }) => {
  const width = useWidth(); // Using a fixed width for demo, replace with useWidth() in production

  const dataDemo = data?.xAxis.map((timestamp, index) => ({
    date: new Date(timestamp * 1000),
    value: data?.yAxis[index],
  }));

  // Custom tooltip component to handle negative values
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = payload[0].value;
      return (
        <div className="p-2 rounded" style={{ backgroundColor: "#383838" }}>
          <p className="text-white text-sm" style={{ margin: 0, fontSize: 14 }}>
            {new Date(label).toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
          <p
            style={{
              color: value < 0 ? "#FF0000" : "#04D9BC",
              fontSize: 14,
              margin: 0,
            }}
          >
            {`${value}€`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-[350px] bg-gray-900">
      <AreaChart width={width - 40} height={240} data={dataDemo}>
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#04D9BC" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#04D9BC" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={(time) => {
            // Skip the first tick (nov 2)
            if (time === dataDemo[0].date) return "";
            return new Date(time)
              .toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })
              .toLowerCase();
          }}
          stroke="#FFFFFF"
          axisLine={{ stroke: "#404040" }}
          tickLine={{ stroke: "#404040" }}
          tick={{ fill: "#FFFFFF", fontSize: 14 }}
        />
        <YAxis
          tickFormatter={(value) => `${value}€`}
          stroke="#FFFFFF"
          axisLine={false} // Remove the axis line but keep the labels
          tickLine={{ stroke: "#404040" }}
          tick={{ fill: "#FFFFFF", fontSize: 14 }}
        />
        <CartesianGrid stroke="#404040" horizontal={true} vertical={false} />
        <Tooltip content={CustomTooltip} />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#04D9BC"
          fill="url(#colorGradient)"
          fillOpacity={1}
        />
      </AreaChart>
    </div>
  );
};

export default GraphDashboard;
