// TabContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  FC,
  useEffect,
} from "react";

// Define the shape of the context
interface TabContextType {
  activeTab: number;
  signUpTab: number;
  setActiveTab: (tabIndex: number) => void;
  setSignUpTab: (tabIndex: number) => void;
  leftPosition: number;
  setLeftPosition: (tabIndex: number) => void;
  profileIdSelected: string;
  setProfileIdSelected: (profileId: string) => void;
  profileCountry: string;
  setProfileCountry: (country: string) => void;
  setNotificationCardOpen: (open: boolean) => void;
  notificationCardOpen: boolean;
}

// Create the context with an empty default value
const TabContext = createContext<TabContextType | undefined>(undefined);

// Define the provider's props type
interface TabProviderProps {
  children: ReactNode;
}

// Create the provider component
const TabProvider: FC<TabProviderProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [notificationCardOpen, setNotificationCardOpen] =
    useState<boolean>(false);
  const [signUpTab, setSignUpTab] = useState<number>(0);
  const [leftPosition, setLeftPosition] = React.useState<number>(250); // Default left position
  const [profileIdSelected, setProfileIdSelected] = React.useState<string>(
    () => {
      return localStorage.getItem("profileIdSelected") || "";
    }
  );
  const [profileCountry, setProfileCountry] = React.useState<string>(() => {
    return localStorage.getItem("profileCountry") || "";
  });
  useEffect(() => {
    localStorage.setItem("profileIdSelected", profileIdSelected);
  }, [profileIdSelected]);
  useEffect(() => {
    localStorage.setItem("profileCountry", profileCountry);
  }, [profileCountry]);
  return (
    <TabContext.Provider
      value={{
        activeTab,
        setActiveTab,
        signUpTab,
        setSignUpTab,
        setLeftPosition,
        leftPosition,
        profileIdSelected,
        setProfileIdSelected,
        profileCountry,
        setProfileCountry,
        setNotificationCardOpen,
        notificationCardOpen,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export { TabContext, TabProvider };
