import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { useAuth } from "@context/AuthContext";

const Container = styled.div`
  border: 1px solid ${theme.colors.grey.normal};
  background-color: ${theme.colors.grey.darker};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  @media (min-width: 767px) {
    border: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DesktopBookieChartDetail = ({
  filterType,
}: {
  filterType: FilterType;
}) => {
  const { getBetsStats } = useStatsChart();
  const betsStats = getBetsStats(filterType);
  const { convertToAppCurrency } = useAuth();

  return (
    <Container>
      <Row>
        <Heading
          title="profile.accounts"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.accounts.toString() || "0"}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.totalProfit"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={convertToAppCurrency(betsStats?.totalProfit || 0)}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.averageProfit"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={convertToAppCurrency(betsStats?.averageProfit || 0)}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.bets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.totalBets.toString() || "0"}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.roi"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={`${betsStats?.roi.toFixed(2) || "0"}%`}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
    </Container>
  );
};

export default DesktopBookieChartDetail;
