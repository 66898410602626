import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";

// Define types for the props
interface ChipProps {
  title: string;
  color?: string; // Optional prop for circle and text color
  bgColor?: string; // Optional prop for background color
}

// Styled component for the chip
const StyledChip = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor || theme.colors.grey.darker};
  border-radius: 50px;
  padding: 0px 8px;
  color: white;
  display: inline-flex;
  align-items: center;
    @media (max-width: 768px) {
  background-color: ${theme.colors.grey.dark};

  }
`;

// Styled component for the circle
const Circle = styled.span<{ color?: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) =>
    color || theme.colors.red}; /* Dynamic color */
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
`;

// Chip component
const Chip: React.FC<ChipProps> = ({
  title,
  color, // Default to white text color
  bgColor = theme.colors.grey.darker, // Default to dark grey background
}) => {
  return (
    <StyledChip bgColor={bgColor}>
      <Circle color={color} />
      <Heading
        fontSize="12px"
        title={title}
        fontWeight="400"
        color={color}
        style={{ wordBreak: "unset" }}
      />
    </StyledChip>
  );
};

export default Chip;
