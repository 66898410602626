import { useErrorHandler } from "@hooks/useErrorHandler";
import { ServerResponse } from "./types";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import request from "@utils/request";

export interface DashBoardDataResponse {
  activeBookies: number;
  activeProfiles: number;
  credits: number;
  profits: Profits;
  profitsData: ProfitsData;
  profitsDif: ProfitsDif;
}

export interface Profits {
  all: number;
  oneDay: number;
  oneMonth: number;
  oneWeek: number;
}

export interface ProfitsData {
  all: GraphData;
  oneDay: GraphData;
  oneMonth: GraphData;
  oneWeek: GraphData;
}

export interface GraphData {
  xAxis: number[];
  yAxis: number[];
}

export interface ProfitsDif {
  oneDay: number;
  oneMonth: number;
  oneWeek: number;
}

export type GetDashboardResponse = ServerResponse<DashBoardDataResponse[]>;
export const getDashboardQKey = "dashboard";
export const useGetDashboard = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: any[]) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<any[], ServerResponse<unknown> | undefined>(
    [getDashboardQKey],
    async () => {
      try {
        const responseData = await request<GetDashboardResponse>({
          method: "get",
          url: `/dashboard`,
        });

        const data = responseData.data.data;

        onSuccess && onSuccess(data);
        return data;
      } catch (error) {
        onError && onError(error as AxiosError<ServerResponse<unknown>>);
        triggerError({ error });
        throw error;
      }
    }
  );

  // return
  return query;
};
