// libraries
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// misc
import request from "@utils/request";
import { AuthResponse, ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { getAddressesQKey } from "./useGetAddress";

export type DeleteAddressResponse = AuthResponse;

export interface DeleteAddressRequest {
  id: string;
}

export const useDeleteAddress = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: DeleteAddressResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // request
  const query = useMutation<
    DeleteAddressResponse,
    ServerResponse<unknown> | undefined,
    DeleteAddressRequest
  >(["deleteAddress"], async ({ id }: DeleteAddressRequest) => {
    try {
      const res = await request<AuthResponse>({
        method: "delete",
        url: "billing/addresses/" + id,
      });

      queryClient.invalidateQueries(getAddressesQKey);
      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};
