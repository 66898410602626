import React from "react";
import logo from "@assets/newLogo.png";
import bell from "@assets/icons/bell.svg";
import hamburger from "@assets/icons/hamburger.svg";
import { theme } from "@styles/themes";
import DrawerMobile from "../mobile/drawer";
import { useNavigate } from "react-router-dom";
import { useGetNotifications } from "@api/useGetNotifications";

const Header = ({setShow,show}) => {
  const { data: notificationsData } = useGetNotifications();
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "0.5px solid",
        borderBottomColor: theme.colors.white.dark,
        padding: "1rem 1.5rem",
        height:"55px"
      }}
    >
      <img src={logo} style={{ width: "7rem" }} alt="logo" />

      <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
        <div style={{ position: "relative",display: "flex",  alignItems: "center" }}>
          <img
            src={bell}
            style={{ width: "1.2rem" }}
            alt="bell icon"
            onClick={() => navigate("/notification")}
          />
          {notificationsData?.notifications?.find(
            (item) => item.isRead !== true
          ) && (
            <div
              style={{
                width: "10px",
                height: "10px",
                right: -2,
                borderRadius: "50%",
                backgroundColor: theme.colors.red.normalHover,
                position: "absolute",
                top: -3,
              }}
            />
          )}
        </div>

        <img
          src={hamburger}
          style={{ width: "1.3rem" }}
          alt="menu icon"
          onClick={() => setShow(true)}
        />
      </div>
      <DrawerMobile setShow={setShow} show={show} />
    </div>
  );
};

export default Header;
