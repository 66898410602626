import Heading from "@components/font/heading";
import { useAuth } from "@context/AuthContext";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { theme } from "@styles/themes";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${theme.colors.grey.normal};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BetsChartDetail = ({ filterType }: { filterType: FilterType }) => {
  const { t } = useTranslation();
  const { getBetsStats } = useStatsChart();
  const { convertToAppCurrency } = useAuth();
  const betsStats = getBetsStats(filterType);

  return (
    <Container>
      <Row>
        <Heading
          title="profile.bets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.totalBets.toString() || "0"}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.winBets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.winBets.toString() || "0"}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.lostBets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.lostBets.toString() || "0"}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.voidBets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.voidBets.toString() || "0"}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title={t("profile.amountBet")}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={`${convertToAppCurrency(betsStats?.amountBet || 0)}`}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.amountEarned"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={`${convertToAppCurrency(betsStats?.amountEarned || 0)}`}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.averageStake"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={`${convertToAppCurrency(betsStats?.averageStake || 0)}`}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.averageOdd"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={betsStats?.averageOdd.toFixed(2) || 0}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.yield"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title={`${betsStats?.yield.toFixed(2) || 0}%`}
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
    </Container>
  );
};

export default BetsChartDetail;
