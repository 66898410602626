import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import SignUpStepTwo from "./signUpStepTwo";
import { countryCodes } from "@utils/countries";
import { useCheckNickname } from "@api/useCheckNickname";
import { useCheckEmail } from "@api/useCheckEmail";
import { useSignUp } from "@api/useSignUp";
import AlertModal from "@components/modal/AlertModal";
import FullScreenLoader from "@components/loader/Loader";
import { regexPatterns } from "@utils/regex";

const SignUpForm = () => {
  // variables
  const { t } = useTranslation();
  const [step, setStep] = React.useState<any>(1);
  const navigate = useNavigate();
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phonePrefix: "",
      phoneNumber: "",
      nickname: "",
      telegramUser: "",
      password: "",
      confirmPassword: "",
      termsConditions: false,
    },
  });
  // console.log(isValid,'isValid');

  const {
    mutate: startSignUp,
    isLoading: isSignUpLoading,
    isError: isSignUpError,
    isSuccess: isSignUpSuccess,
    reset: resetSignUp,
  } = useSignUp();

  // Nickname Check Hook
  const checkNickname = useCheckNickname({
    onSuccess: (res) => {
      if (res.success) {
        // clearErrors("nickname");
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("nickname", {
          type: "manual",
          message: t("signUp.usernameAlreadyExist"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // Email Check Hook
  const checkEmail = useCheckEmail({
    onSuccess: (res) => {
      if (res.success) {
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("email", {
          type: "manual",
          message: t("signUp.emailAlreadyRegistered"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // functions
  const onSubmit = async (values: any) => {
    const formData = {
      nickname: values?.nickname,
      email: values?.email,
      password: values?.password,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phonePrefix: values?.phonePrefix?.value,
      telegramUser: values?.telegramUser,
      termsConditions: values?.termsConditions,
    };

    startSignUp(formData);
  };

  const onContinue = (data: any) => {
    if (step === 1) {
      setStep(2);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleSignUpSuccess = () => {
    resetSignUp();
    navigate("/login");
  };
  const nicknameValue = watch("nickname");
  const emailValue = watch("email");

  const handleOnBlurNickName = async () => {
    const isValid = await trigger("nickname");
    if (nicknameValue && isValid) {
      clearErrors("nickname");
    }
    checkNickname.mutate({ nickname: nicknameValue });
  };
  const handleOnBlurEmail = async () => {
    const isValid = await trigger("email");
    if (nicknameValue && isValid) {
      clearErrors("email");
    }
    checkEmail.mutate({ email: emailValue });
  };
  // returns
  return (
    <div className="mb-3" style={{ placeSelf: "center" }}>
      <Heading
        title="signUp.signupTitle"
        fontSize="29px"
        fontWeight="700"
        color={theme.colors.white.normal}
        className="mb-4"
      />
      <Heading
        title={`${t("step")} ${step} ${t("of")} 2`}
        fontSize="14px"
        fontWeight="400"
        className="mb-1"
        color={theme.colors.white.dark}
      />
      <Heading
        style={{ marginBottom: "24px" }}
        title={
          step == 1
            ? t("signUp.tellUsAboutYourself")
            : t("signUp.readTermsAndConditions")
        }
        fontSize="20px"
        fontWeight="700"
        color={theme.colors.white.normal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {step == 1 ? (
          <Row className="g-2" style={{ maxWidth: "500px" }}>
            <Col md={6} sm={12} xs={12}>
              <TextField
                control={control}
                name="firstName"
                bgColor={theme.colors.grey.dark}
                size="sm"
                onBlur={(e) => {
                  trigger("firstName");
                }}
                placeholder={t("signUp.name")}
                errors={errors.firstName}
                rules={{
                  required: t("errors.required"),
                  pattern: {
                    value: regexPatterns?.regOnlyChar,
                    message: t("errors.namePattern"),
                  },
                }}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <TextField
                control={control}
                name="lastName"
                onBlur={(e) => {
                  trigger("lastName");
                }}
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder={t("signUp.lastName")}
                errors={errors.lastName}
                rules={{
                  required: t("errors.required"),
                  pattern: {
                    value: regexPatterns?.regOnlyChar,
                    message: t("errors.namePattern"),
                  },
                }}
              />
            </Col>

            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="email"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder={t("signUp.email")}
                errors={errors.email}
                onBlur={handleOnBlurEmail}
                rules={{
                  required: t("errors.required"),
                  pattern: {
                    value: regexPatterns?.regEmail,
                    message: t("errors.emailPattern"),
                  },
                }}
              />
            </Col>

            <Col md={3} sm={3} xs={6} className="d-flex align-items-start">
              <CountryCodePicker
                bgColor={theme.colors.grey.dark}
                size="sm"
                options={countryCodes}
                placeholder={t("signUp.prefix")}
                control={control}
                name="phonePrefix"
                setValue={setValue}
                errors={errors.phonePrefix}
                rules={{ required: false }}
              />
            </Col>

            <Col md={9} sm={9} xs={6}>
              <TextField
                control={control}
                name="phoneNumber"
                onBlur={(e) => {
                  trigger("phoneNumber");
                }}
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder={t("signUp.phoneNumber")}
                errors={errors.phoneNumber}
                rules={{
                  required: false,
                  pattern: {
                    value: regexPatterns?.regOnlyNum,
                    message: t("errors.phoneNumberPattern"),
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="nickname"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder={t("signUp.username")}
                errors={errors.nickname}
                onBlur={handleOnBlurNickName}
                rules={{
                  required: t("errors.required"),
                  pattern: {
                    value: regexPatterns.regUsername,
                    message: t("errors.usernamePattern"),
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="telegramUser"
                defaultValue={""} // Start with an empty field to show placeholder
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="@Telegram" // Placeholder remains visible initially
                onBlur={() => {
                  const value = getValues("telegramUser") || "";
                  if (value && !value.startsWith("@")) {
                    setValue("telegramUser", `@${value}`); // Ensure "@" on blur
                  }
                  trigger("telegramUser");
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value.startsWith("@") && value.length > 0) {
                    setValue("telegramUser", `@${value}`); // Add "@" dynamically
                  } else {
                    setValue("telegramUser", value); // Allow typing normally
                  }
                }}
                errors={errors.telegramUser}
                rules={{
                  required: false,
                  pattern: {
                    value: regexPatterns?.regTelegram, // Validate Telegram username format
                    message: t("errors.telegramPattern"),
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="password"
                onBlur={(e) => {
                  trigger("password");
                }}
                bgColor={theme.colors.grey.dark}
                size="sm"
                type="password"
                placeholder={t("signUp.password")}
                errors={errors.password}
                rules={{
                  required: t("errors.required"),
                  minLength: {
                    value: 6,
                    message: t("errors.passwordPattern"),
                  },
                  pattern: {
                    value: regexPatterns?.regPassword,
                    message: t("errors.passwordPattern"),
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="confirmPassword"
                onBlur={(e) => {
                  trigger("confirmPassword");
                }}
                bgColor={theme.colors.grey.dark}
                size="sm"
                type="password"
                placeholder={t("signUp.confirmPassword")}
                errors={errors.confirmPassword}
                rules={{
                  required: t("errors.required"),
                  validate: (value: any) =>
                    value === watch("password") || t("errors.passwordNotMatch"),
                }}
              />
            </Col>
          </Row>
        ) : (
          <SignUpStepTwo control={control} errors={errors} />
        )}
        <Button
          title="actions.back"
          outline={true}
          type="button"
          onClick={() => (step == 2 ? setStep(1) : navigate(-1))}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
            minWidth: "12em",
            marginTop: "16px",
            marginRight: "20px",
          }}
        />
        <Button
          title={step === 2 ? "signIn.signUpButton" : "payments.continue"}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
            minWidth: "12em",
            marginTop: "16px",
          }}
          // disabled={errors == {}?false:false}
          onClick={handleSubmit(onContinue)}
        />
      </form>

      <AlertModal
        open={isSignUpError}
        type="error"
        title={"signUp.somethingWrong"}
        description={"signUp.somethingWrongDescription"}
        onActionClick={resetSignUp}
      />

      <AlertModal
        open={isSignUpSuccess}
        type="success"
        title={"signUp.success"}
        description={"signUp.successDescription"}
        onActionClick={handleSignUpSuccess}
      />
      {isSignUpLoading && <FullScreenLoader />}
    </div>
  );
};

export default SignUpForm;
