import React, { useEffect } from "react";
import NotificationCard from "./notificationCard";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { useGetNotifications } from "@api/useGetNotifications";
import SkeletonLoader from "@components/loader/skeleton";
import { useDeleteAllNotification } from "@api/useDeleteAllNotifications";
import NotificationEmpty from "@assets/notifications/emptyNotification.svg";
import { useReadNotification } from "@api/useReadNotification";

const NotificationSection = () => {
  // variables
  const { data: notificationData, isLoading,refetch } = useGetNotifications();
  const { mutate } = useDeleteAllNotification({onSuccess:()=>{
    refetch()
  }});
  const { mutate: readMutate } = useReadNotification();

  // effect
  useEffect(() => {
    notificationData?.notifications?.forEach((notify: any) => {
      if (!notify?.isRead) {
        readMutate(notify?.notificationId);
      }
    });
  }, []);

  // renders
  return (
    <div style={{ padding: "16px 0" }}>
     {notificationData?.notifications?.length > 0&& <Heading
        title="profile.deleteAll"
        fontSize="14px"
        fontWeight="400"
        onClick={() => mutate("deleteAll")}
        color={theme.colors.white.normal}
        style={{
          textDecoration: "underline",
          textAlign: "right",
          cursor: "pointer",
        }}
      />}
      {isLoading ? (
        <SkeletonLoader height="77px" />
      ) : notificationData?.notifications?.length <= 0 ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <img
            src={NotificationEmpty}
            style={{ minWidth: "210px" }}
            alt="Notify Empty"
          />
        </div>
      ) : (
        notificationData?.notifications?.map((notify: any, index: number) => {
          const date = new Date(notify?.date * 1000);
          const formattedDate = date.toLocaleDateString("en-GB");
          return (
            <NotificationCard
              notifyId={notify?.notificationId}
              key={index}
              isIncidence={notify?.isIncidence}
              title={notify?.title}
              date={formattedDate}
              content={notify?.description}
              isRead={notify?.isRead}

            />
          );
        })
      )}
    </div>
  );
};

export default NotificationSection;
