import { theme } from "@styles/themes";
import React from "react";
import Select from "react-select";
import styled from "styled-components";

import { Controller } from "react-hook-form";
import search from "@assets/icons/search.svg";
import { useTranslation } from "react-i18next";
import flags from "@assets/flags";
import { Icon } from "@iconify/react";

// Styled-components
const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  position: relative;
`;

const CustomSelect = styled(Select)`
  width: 100%;
  color: ${theme.colors.white.normal};

  .react-select__option {
    color: ${theme.colors.white.normal}; /* Default text color */
    display: flex;
    align-items: center;
  }

  .react-select__control {
    padding-right: 18px;
    border: 1px solid
      ${(props) =>
        props.errors ? theme.colors.red.normal : theme.colors.grey.dark};
    border-radius: 8px;
    background: ${theme.colors.grey.dark};
    color: ${theme.colors.white.normal};
    box-shadow: none;

    &:focus {
      border-color: ${theme.colors.white.normalActive};
      outline: none;
    }
  }

  .react-select__placeholder {
    color: ${(props) =>
      props.errors ? theme.colors.red.normal : theme.colors.white.dark};
    white-space: nowrap;
  }
  .react-select__indicators {
    display: none;
  }
  .react-select__single-value {
    color: ${theme.colors.white.normal};
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }

  .react-select__input {
    color: ${theme.colors.white.normal}!important; /* Input text color */
  }

  .react-select__menu {
    background-color: ${theme.colors.grey.dark};
    z-index: 1000;
    top: 1;
    color: ${theme.colors.white.normal};

    scrollbar-width: none; /* For Firefox */
    &::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Opera */
    }
  }

  .react-select__menu-list {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: ${theme.colors.grey.darker} transparent; /* Thumb color, track transparent */

    &::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.white.normal}; /* Thumb color */
      border-radius: 10px; /* Rounded edges for the scrollbar thumb */
    }

    &::-webkit-scrollbar-button {
      display: none; /* Hide the up/down arrow buttons */
    }
  }
`;

const StyledLabel = styled.label<{ active: boolean; bgColor: string }>`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: ${(props) => props.bgColor};
  padding: 0 5px;
  transition: 0.2s ease all;
  color: ${theme.colors.white.dark};
  pointer-events: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${({ active }) =>
    active &&
    `left:10px;
    top: 0px;
    z-index:5;
    font-size: 10px;
    color: ${theme.colors.white.normal};
  `}
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.red.normal};
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;
`;
const StyledSearch = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 10px;
  width: 18px;
  height: 18px;
  color: ${theme.colors.white.dark};
`;

// Main Component
const CountryCodePicker = ({
  placeholder,
  control,
  endIcon,
  options,
  size = "md",
  bgColor,
  labelText,
  name,
  assistiveText,
  rules,
  disabled,
  defaultValue = null,
  errors,
}: any) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }}>
      <Container>
        <StyledLabel active={true} bgColor={theme.colors.grey.dark}>
          {t("profile.phonePrefix")}
        </StyledLabel>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <CustomSelect
                value={value || defaultValue}
                onChange={onChange}
                errors={errors}
                options={options}
                classNamePrefix="react-select"
                placeholder={placeholder}
                isClearable // This enables the clear functionality
                formatOptionLabel={({ label, flag }) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={`${flags[flag?.toLowerCase()]}`}
                        alt=""
                        style={{
                          width: "24px",
                          height: "16px",
                          marginRight: "8px",
                        }}
                      />
                      {label.includes("+") ? "" : "+"}
                      <span>{label}</span>
                    </div>
                  );
                }}
              />
            );
          }}
        />

        <StyledSearch icon="tabler:search" />
      </Container>
      {errors && (
        <ErrorMessage>{errors.message || t("errors.required")}</ErrorMessage>
      )}
    </div>
  );
};

export default CountryCodePicker;
