// libraries
import { useQuery } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

// Update the Profile interface
export interface Profile {
  bookies: Array<{
    accountId: string;
    birthday: number;
    closingDate: number;
    creditLimit: string;
    currentOB_number: number;
    error: boolean;
    errorMessage: string;
    errorType: number;
    isEnabled: boolean;
    isRunning: boolean;
    last_bank: number;
    password: string;
    profit: number;
    scheduler: [];
    stake: number;
    total_bank: number;
    username: string;
  }>;
  country: string;
  isActive: boolean;
  isRead?: boolean;
  profileId: string;
  profileName: string;
}

// Add a new interface for the full response
export interface GetProfilesFullResponse {
  credits: number;
  profiles: Profile[];
  profits: {
    all: number;
    oneDay: number;
    oneMonth: number;
    oneWeek: number;
  };
  profitsDif: {
    oneDay: number;
    oneMonth: number;
    oneWeek: number;
  };
}

export type GetProfilesResponse = ServerResponse<GetProfilesFullResponse>;

export const getProfilesQKey = "profiles";

export const useGetProfiles = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: GetProfilesFullResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<
    GetProfilesFullResponse,
    ServerResponse<unknown> | undefined
  >([getProfilesQKey], async () => {
    try {
      const profilesData = await request<GetProfilesResponse>({
        method: "get",
        url: "/profiles",
      });

      const data = profilesData.data.data;

      onSuccess && onSuccess(data);
      return data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
      throw error;
    }
  });

  // return
  return query;
};
