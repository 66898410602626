// libraries
import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

// components
import RedirectPay from "@pages/Payments/RedirectPay";
import RegistrationForm from "@pages/Clients/RegisterClient";

// misc
import { useAuth } from "@context/AuthContext";

import { RefreshModal } from "@components/RefreshModal";
import PaymentPage from "@pages/Payment";
import PrimaryLayout from "../layout/desktop";
import BillingDetailPage from "@pages/Billing";
import SuccessPage from "@pages/SuccessPage";
import NewLogin from "@pages/Login/newLogin";
import ForgotPassword from "@pages/ForgotPassword";
import ProfilePage from "@pages/Profile";
import MyDataPage from "@pages/Profile/MyData";
import BillingDetailsPage from "@pages/Profile/BillingDetails";
import BillingDetailsAdd from "@pages/Profile/BillingDetailsAdd";
import PaymentSeeMore from "../section/paymentSection/paymentSeeMore";
import MySettingPage from "@pages/Profile/MySetting";
import ContactUsPage from "@pages/Profile/ContactUs";
import ProfileBookieMobile from "@pages/Profile/profileBookie";
import ProfileBookieAdd from "@pages/Profile/profileBookie/addForm";
import BookieRecord from "@pages/Profile/BookieRecord";
import BookieArchive from "@pages/Profile/profileBookie/archive";
import StatsPage from "@pages/Stats";
import NewDashboard from "@pages/NewDashboard";
import Notification from "@pages/Notification";
import Login from "@pages/Login/Login";
import { useMediaQuery } from "react-responsive";
import { useQueryClient } from "react-query";
import BankTransferPage from "@pages/BankTransfer";
// types
export type MainRouterProps = {};

export const MainRouterContent: React.FC = () => {
  // This component has Router context
  const location = useLocation();
  const queryClient = useQueryClient();
  const { isUnauthorized, initLoading } = useAuth();
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });

  React.useEffect(() => {
    queryClient.invalidateQueries();
  }, [location.pathname, queryClient]);

  if (initLoading) {
    return null;
  }

  return (
    <>
      {isUnauthorized ? (
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<NewLogin />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/resetpass" element={<ForgotPassword />} />
          <Route path="/confirmaccount" element={<Login />} />
        </Routes>
      ) : (
        <div className="app-main">
          <PrimaryLayout>
            <RefreshModal />
            <Routes>
              {isDesktop ? (
                <Route path="*" element={<Navigate to="/profile" replace />} />
              ) : (
                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                />
              )}
              <Route path="/payments" element={<PaymentPage />} />
              <Route path="/payments/records" element={<PaymentSeeMore />} />
              <Route
                path="/payments/billingdetails"
                element={<BillingDetailPage />}
              />
              <Route path="/payments/success" element={<SuccessPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/profile/bookie" element={<ProfileBookieMobile />} />
              <Route
                path="/profile/bookie/add/:id"
                element={<ProfileBookieAdd />}
              />
              <Route path="/profile/bookie/record" element={<BookieRecord />} />
              <Route
                path="/profile/bookie/archive"
                element={<BookieArchive />}
              />
              <Route path="/profile/mydata" element={<MyDataPage />} />
              <Route path="/profile/setting" element={<MySettingPage />} />
              <Route path="/profile/contactus" element={<ContactUsPage />} />
              <Route
                path="/profile/billingdetails"
                element={<BillingDetailsPage />}
              />
              <Route
                path="/profile/billingdetails/add"
                element={<BillingDetailsAdd />}
              />{" "}
              <Route
                path="/payments/banktransfer"
                element={<BankTransferPage />}
              />
              <Route path="/stats" element={<StatsPage />} />
              <Route path="/dashboard" element={<NewDashboard />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/redirectPay" element={<RedirectPay />} />
            </Routes>
          </PrimaryLayout>
        </div>
      )}
    </>
  );
};

export const MainRouter: React.FC<MainRouterProps> = () => {
  return (
    <BrowserRouter>
      <MainRouterContent />
    </BrowserRouter>
  );
};
