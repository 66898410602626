import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useState } from "react";
import styled from "styled-components";
import BookiesChart from "./bookiesChart";
import BetsChart from "./betsChart";
import BookieChartDetail from "./bookieChartDetail";
import BookieChartFilter from "./bookieChartFilter";
import BetsChartDetail from "./betsChartDetail";
import BetsChartFilter from "./betsChartFilter";
import DesktopChartSection from "./desktopChartSection";
import DrawerFilter from "./drawerFIlter";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { Loading } from "@components/loader/Loading";

const TabContainer = styled.div`
  display: flex;
  border: 1px solid ${theme.colors.grey.dark};
  border-radius: 20px;
  width: 100%;
  padding: 4px;
  gap: 8px;
  margin: 16px 0;
  justify-content: center;
`;

const TabOption = styled.button<{ active: boolean }>`
  padding: 2px 14px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? `${theme.colors.grey.dark}` : "transparent"};
  transition: all 0.3s ease;
  flex: 1;
  max-width: 200px;
`;
const DesktopHide = styled.div`
  max-width: 465px;
  padding: 24px;
  @media (min-width: 1201px) {
    display: none;
  }
  @media (max-width: 540px) {
    padding: 0px;
  }
`;

const StatSection = () => {
  const [activeTab, setActiveTab] = useState<FilterType>("bookies");
  const [openFilter, setOpenFilter] = React.useState(false);
  const { isLoading } = useStatsChart();

  return (
    <div>
      <DesktopChartSection
        setOpenFilter={setOpenFilter}
        setActiveTab={setActiveTab}
      />
      <DesktopHide>
        <TabContainer>
          <TabOption
            active={activeTab === "bookies"}
            onClick={() => setActiveTab("bookies")}
          >
            <Heading
              title="profile.bookies"
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </TabOption>
          <TabOption
            active={activeTab === "bets"}
            onClick={() => setActiveTab("bets")}
          >
            <Heading
              title="profile.bets"
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </TabOption>
        </TabContainer>
        {activeTab === "bookies" && (
          <>
            <BookieChartFilter
              setOpenFilter={setOpenFilter}
              openFilter={openFilter}
            />
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <Loading />
              </div>
            ) : (
              <>
                <BookiesChart filterType="bookies" />
                <BookieChartDetail filterType="bookies" />
              </>
            )}
          </>
        )}
        {activeTab === "bets" && (
          <>
            <BetsChartFilter
              setOpenFilter={setOpenFilter}
              openFilter={openFilter}
            />
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <Loading />
              </div>
            ) : (
              <>
                <BetsChart filterType="bets" />
                <BetsChartDetail filterType="bets" />
              </>
            )}
          </>
        )}
      </DesktopHide>

      <DrawerFilter
        setShow={setOpenFilter}
        show={openFilter}
        filterType={activeTab}
      />
    </div>
  );
};

export default StatSection;
