import bet365Logo from "@assets/logosBookies/Bets365.png";
import retaLogo from "@assets/logosBookies/retabets.png";
import winaLogo from "@assets/logosBookies/Winamaxs.png";
import bwinLogo from "@assets/logosBookies/Bwins.png";
import ochoLogo from "@assets/logosBookies/888.png";
import betfairLogo from "@assets/logosBookies/Betfairs.png";

import es from "@assets/flags/es.svg";
import gb from "@assets/flags/gb.svg";
import br from "@assets/flags/br.svg";
import mx from "@assets/flags/mx.svg";
import cl from "@assets/flags/cl.svg";

export const bookyImageDictionary = {
  bet365: bet365Logo,
  winamax: winaLogo,
  retabet: retaLogo,
  bwin: bwinLogo,
  888: ochoLogo,
  betfair: betfairLogo,
};

export const flagMapping = {
  ES: es,
  GB: gb,
  BR: br,
  MX: mx,
  CL: cl,
};

export const countries = [
  {
    label: "Spain",
    value: "Spain",
    isoCode: "ES",
    icon: es,
    bookies: ["Bet365", "Retabet", "Winamax", "Bwin", "Betfair"],
  },
  {
    label: "United Kingdom",
    value: "United Kingdom",
    isoCode: "GB",
    icon: gb,
    bookies: ["Bet365"],
  },
  {
    label: "Brazil",
    value: "Brazil",
    isoCode: "BR",
    icon: br,
    bookies: ["Bet365"],
  },
  {
    label: "Mexico",
    value: "Mexico",
    isoCode: "MX",
    icon: mx,
    bookies: ["Bwin", "Betfair"],
  },
  // Add more countries with their respective flag images and bookies
];

export const bookies = [
  {
    label: "Bet365",
    value: "Bet365",
    icon: bet365Logo,
    countries: [
      { value: "ES", label: "Spain" },
      { value: "GB", label: "United Kingdom" },
      { value: "BR", label: "Brazil" },
    ],
  },
  {
    label: "Retabet",
    value: "Retabet",
    icon: retaLogo,
    countries: [{ value: "ES", label: "Spain" }],
  },
  {
    label: "Winamax",
    value: "Winamax",
    icon: winaLogo,
    countries: [{ value: "ES", label: "Spain" }],
  },
  {
    label: "Bwin",
    value: "Bwin",
    icon: bwinLogo,
    countries: [
      { value: "ES", label: "Spain" },
      { value: "MX", label: "Mexico" },
    ],
  },
  {
    label: "Betfair",
    value: "Betfair",
    icon: betfairLogo,
    countries: [
      { value: "ES", label: "Spain" },
      { value: "MX", label: "Mexico" },
    ],
  },
  // Add more bookies with their respective countries
];

export const serviceMapping = {
  Bet365: "ValueBet",
  Retabet: "RetaBot",
  Winamax: "WinaBot",
  Bwin: "BwinBot",
  888: "888Bot",
  Betfair: "BetfairBot",
};
