import React from "react";
import {
  StyleContent,
  StyledChip,
  StyledContainer,
  StyledHeader,
  StyledImg,
  StyleFooter,
  TitleContainer,
} from "./styledComponent";
import football from "@assets/sportsIcon/football.svg";
import volleyball from "@assets/sportsIcon/ball-volleyball.svg";
import bowling from "@assets/sportsIcon/ball-bowling.svg";
import tennis from "@assets/sportsIcon/ball-tennis.svg";
import basketball from "@assets/sportsIcon/ball-basketball.svg";
import baseball from "@assets/sportsIcon/ball-baseball.svg";
import americanfootball from "@assets/sportsIcon/ball-american-football.svg";
import esports from "@assets/sportsIcon/ball-american-football.svg";
import hockey from "@assets/sportsIcon/ball-american-football.svg";
import tableTennis from "@assets/sportsIcon/ball-american-football.svg";
import others from "@assets/sportsIcon/ball-american-football.svg";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { BookieRecord, Result } from "@api/types";
import { useSettings } from "@context/SettingsContext";
import { useAuth } from "@context/AuthContext";

const BookieCard: React.FC<BookieRecord> = ({
  result,
  sport,
  home,
  away,
  market,
  odd,
  eventDate,
  placedDate,
  betType,
  amount,
  payout,
}) => {
  const { formatTimestamp } = useSettings();
  const { convertToAppCurrency } = useAuth();

  return (
    <StyledContainer>
      <StyledHeader>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <StyledImg
              src={
                sport == "Basketball"
                  ? basketball
                  : sport == "Soccer"
                  ? football
                  : sport == "Tennis"
                  ? tennis
                  : sport == "Baseball"
                  ? baseball
                  : sport == "Bowling"
                  ? bowling
                  : sport == "Volleyball"
                  ? volleyball
                  : sport == "Esports"
                  ? esports
                  : sport == "Hockey"
                  ? hockey
                  : sport == "Table Tennis"
                  ? tableTennis
                  : sport == "American Football" || sport == "Rugby"
                  ? americanfootball
                  : others
              }
              alt="sport icon"
            />
            <div style={{ marginRight: "4px" }}>
              <Heading
                title={`${home} - ${away}`}
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
                style={{ lineHeight: "16px" }}
              />
              <Heading
                title={formatTimestamp(eventDate, "datetime")}
                fontSize="12px"
                fontWeight="400"
                color={theme.colors.white.dark}
                style={{ lineHeight: "16px" }}
              />
            </div>
          </div>

          <StyledChip>
            <Heading
              title={result}
              fontSize="12px"
              fontWeight="400"
              color={
                result === Result.Win
                  ? theme.colors.green.normal
                  : result === Result.Loss
                  ? theme.colors.red.normal
                  : result === Result.Void
                  ? theme.colors.white.dark
                  : result === Result.Pending
                  ? theme.colors.yellow.normal
                  : theme.colors.white.normal
              }
              style={{
                lineHeight: "16px",
                textTransform: "capitalize",
                width: "max-content",
              }}
            />
          </StyledChip>
        </div>
      </StyledHeader>
      <StyleContent>
        <TitleContainer>
          <Heading
            title="Market :"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px" }}
          />
          <Heading
            title={market}
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
            style={{ lineHeight: "16px" }}
          />
        </TitleContainer>
        <TitleContainer>
          <Heading
            title="Bet :"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px" }}
          />
          <Heading
            title={`${betType} | ${formatTimestamp(placedDate, "datetime")}`}
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
            style={{ lineHeight: "16px" }}
          />
        </TitleContainer>
        <TitleContainer>
          <Heading
            title="ODD :"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px" }}
          />
          <Heading
            title={`${odd}`}
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
            style={{ lineHeight: "16px" }}
          />
        </TitleContainer>
      </StyleContent>
      <StyleFooter>
        <div className="d-flex align-items-center">
          <Heading
            title="Stake"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px", marginRight: "4px" }}
          />
          <Heading
            title={convertToAppCurrency(amount)}
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
            style={{ lineHeight: "19px" }}
          />
        </div>
        <div className="d-flex align-items-center">
          <Heading
            title="Payout"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px", marginRight: "4px" }}
          />
          <Heading
            title={convertToAppCurrency(payout)}
            fontSize="14px"
            fontWeight="700"
            color={
              result === Result.Win
                ? theme.colors.green.normal
                : theme.colors.white.normal
            }
            style={{ lineHeight: "19px" }}
          />
        </div>
      </StyleFooter>
    </StyledContainer>
  );
};

export default BookieCard;
