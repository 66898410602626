import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { Control } from "react-hook-form";
import * as logosBookies from "@assets/logosBookies";
import soccer from "@assets/sportsIcon/football.svg";
import volleyball from "@assets/sportsIcon/ball-volleyball.svg";
import bowling from "@assets/sportsIcon/ball-bowling.svg";
import tennis from "@assets/sportsIcon/ball-tennis.svg";
import basketball from "@assets/sportsIcon/ball-basketball.svg";
import baseball from "@assets/sportsIcon/ball-baseball.svg";
import americanfootball from "@assets/sportsIcon/ball-american-football.svg";
import esports from "@assets/sportsIcon/esports.svg";
import hockey from "@assets/sportsIcon/hockey.svg";
import tableTennis from "@assets/sportsIcon/table-tennis.svg";
import handball from "@assets/sportsIcon/handball.svg";
import others from "@assets/sportsIcon/others.svg";

const sportIcons = {
  soccer,
  volleyball,
  bowling,
  tennis,
  basketball,
  baseball,
  americanfootball,
  esports,
  hockey,
  tableTennis,
  handball,
  others,
};

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;
  margin-right: 8px;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

interface FilterSectionProps {
  control: Control<any>;
  filterType: FilterType;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  control,
  filterType,
}) => {
  const { t } = useTranslation();
  const { statsData, tempFilters, setTempFilter } = useStatsChart();

  const [searchTerm, setSearchTerm] = useState("");
  const [showAllSports, setShowAllSports] = useState(false);
  const [visibleBookiesCount, setvisibleBookiesCount] = useState(5);
  const [visibleProfilesCount, setVisibleProfilesCount] = useState(5);

  // Extract unique profiles, bookies, and sports from statsData
  const { profiles, bookies, sports } = useMemo(() => {
    if (!statsData) return { profiles: [], bookies: [], sports: [] };

    const profilesSet = new Set<{ profileId: string; profileName: string }>();
    const bookiesSet = new Set<string>();
    const sportsSet = new Set<string>();

    Object.entries(statsData.stats).forEach(([bookie, profiles]) => {
      bookiesSet.add(bookie);
      profiles.forEach((profile) => {
        profilesSet.add({
          profileId: profile.profileId,
          profileName: profile.profileName,
        });
        Object.keys(profile.sports).forEach((sport) => {
          sportsSet.add(sport);
        });
      });
    });

    return {
      profiles: Array.from(profilesSet),
      bookies: Array.from(bookiesSet),
      sports: Array.from(sportsSet),
    };
  }, [statsData]);

  const handleProfileToggle = (profileId: string) => {
    setTempFilter(
      filterType,
      "profiles",
      tempFilters[filterType].profiles.includes(profileId)
        ? tempFilters[filterType].profiles.filter((id) => id !== profileId)
        : [...tempFilters[filterType].profiles, profileId]
    );
  };

  const handleBookieToggle = (bookie: string) => {
    setTempFilter(
      filterType,
      "bookies",
      tempFilters[filterType].bookies.includes(bookie)
        ? tempFilters[filterType].bookies.filter((b) => b !== bookie)
        : [...tempFilters[filterType].bookies, bookie]
    );
  };

  const handleSportToggle = (sport: string) => {
    setTempFilter(
      filterType,
      "sports",
      tempFilters[filterType].sports.includes(sport)
        ? tempFilters[filterType].sports.filter((s) => s !== sport)
        : [...tempFilters[filterType].sports, sport]
    );
  };

  const toggleAllProfiles = () => {
    setTempFilter(
      filterType,
      "profiles",
      tempFilters[filterType].profiles.length === profiles.length
        ? []
        : profiles.map((profile) => profile.profileId)
    );
  };

  const toggleAllBookies = () => {
    setTempFilter(
      filterType,
      "bookies",
      tempFilters[filterType].bookies.length === bookies.length ? [] : bookies
    );
  };

  const toggleAllSports = () => {
    setTempFilter(
      filterType,
      "sports",
      tempFilters[filterType].sports.length === sports.length ? [] : sports
    );
  };

  return (
    <div style={{ paddingLeft: "8px" }}>
      <div
        style={{
          borderBottom: `1px solid ${theme.colors.grey.dark}`,
          paddingBottom: "16px",
        }}
      >
        <Heading
          title="profile.profile"
          fontSize="14px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        {profiles && profiles?.length > 15 && (
          <TextField
            name="searchProfile"
            placeholder={t("payments.searchProfile")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="sm"
            style={{ marginTop: "20px" }}
            control={control}
            endIcon={
              <Icon icon="majesticons:search-line" color="#BFBFBF" width={24} />
            }
          />
        )}

        <div className="d-flex align-items-center mb-3 mt-3">
          <Checkbox
            checked={
              tempFilters[filterType].profiles.length === profiles.length
            }
            onChange={toggleAllProfiles}
          />
          <Heading
            title={t("profile.selectAll")}
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </div>

        {profiles
          .filter((profile) =>
            profile.profileName.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .slice(0, visibleProfilesCount)
          .map((profile) => (
            <div
              className="d-flex align-items-center mb-2"
              key={profile.profileId}
            >
              <Checkbox
                checked={tempFilters[filterType].profiles.includes(
                  profile.profileId
                )}
                onChange={() => handleProfileToggle(profile.profileId)}
              />
              <Heading
                title={profile.profileName}
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
              />
            </div>
          ))}

        {/* No Results Message */}
        {profiles.filter((profile) =>
          profile.profileName.toLowerCase().includes(searchTerm.toLowerCase())
        ).length === 0 &&
          searchTerm && (
            <div className="d-flex align-items-center mb-2">
              <Heading
                title={t("actions.noResultsFound")}
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
              />
            </div>
          )}

        <div className="d-flex align-items-center" style={{ gap: "16px" }}>
          {profiles?.length > visibleProfilesCount && (
            <div className="d-flex align-items-center">
              <Heading
                title="actions.seeMore"
                fontSize="14px"
                fontWeight="400"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                color={theme.colors.white.normal}
                onClick={() =>
                  setVisibleProfilesCount(visibleProfilesCount + 10)
                }
              />
            </div>
          )}

          {visibleProfilesCount > 5 && (
            <div className="d-flex align-items-center">
              <Heading
                title="actions.hide"
                fontSize="14px"
                fontWeight="400"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                color={theme.colors.white.normal}
                onClick={() => setVisibleProfilesCount(5)}
              />
            </div>
          )}
        </div>
      </div>

      {/* Bookies Section */}
      <div
        style={{
          borderBottom: `1px solid ${theme.colors.grey.dark}`,
          paddingBottom: "16px",
        }}
      >
        <div style={{ marginTop: "16px" }}>
          <Heading
            title="profile.bookies"
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />

          <div className="d-flex align-items-center mb-3 mt-3">
            <Checkbox
              checked={
                tempFilters[filterType].bookies.length === bookies.length
              }
              onChange={toggleAllBookies}
            />
            <Heading
              title={t("profile.selectAll")}  
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </div>

          {bookies.slice(0, visibleBookiesCount).map((bookie) => (
            <div className="d-flex align-items-center mb-2" key={bookie}>
              <Checkbox
                checked={tempFilters[filterType].bookies.includes(bookie)}
                onChange={() => handleBookieToggle(bookie)}
              />
              <img
                src={logosBookies[bookie.toLowerCase()]}
                alt={`${bookie} logo`}
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "4px",
                  borderRadius: "50%",
                }}
              />
              <Heading
                title={bookie}
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
              />
            </div>
          ))}

          <div className="d-flex align-items-center" style={{ gap: "16px" }}>
            {bookies.length > visibleBookiesCount && (
              <div className="d-flex align-items-center">
                <Heading
                  title="actions.seeMore"
                  fontSize="14px"
                  fontWeight="400"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  color={theme.colors.white.normal}
                  onClick={() =>
                    setvisibleBookiesCount(visibleBookiesCount + 10)
                  }
                />
              </div>
            )}

            {visibleBookiesCount > 5 && (
              <div className="d-flex align-items-center">
                <Heading
                  title="actions.hide"
                  fontSize="14px"
                  fontWeight="400"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  color={theme.colors.white.normal}
                  onClick={() => setvisibleBookiesCount(5)}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Sports Section */}
      <div>
        <div style={{ marginTop: "12px" }}>
          <Heading
            title="profile.sports"
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />

          <div className="d-flex align-items-center mb-3 mt-3">
            <Checkbox
              checked={tempFilters[filterType].sports.length === sports.length}
              onChange={toggleAllSports}
            />
            <Heading
              title={t("profile.selectAll")}
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </div>

          {sports.slice(0, showAllSports ? undefined : 5).map((sport) => (
            <div  style={{display:"flex", height:"24px", alignItems: "center", marginBottom:"8px"}} key={sport}>
              <Checkbox
                checked={tempFilters[filterType].sports.includes(sport)}
                onChange={() => handleSportToggle(sport)}
              />
              <img
                src={sportIcons[sport.toLowerCase()] || sportIcons.others}
                alt={`${sport} logo`}
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "4px",
                }}
              />
              <Heading
                title={sport}
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
                style={{ textTransform: "capitalize" }}
              />
            </div>
          ))}

          {sports.length > 5 && (
            <div className="d-flex align-items-center">
              <Heading
                title={showAllSports ? "actions.hide" : "actions.seeMore"}
                fontSize="14px"
                fontWeight="400"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                color={theme.colors.white.normal}
                onClick={() => setShowAllSports(!showAllSports)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
