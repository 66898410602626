import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import PaymentDetailInfo from "@components/paymentInfoCard";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import SkeletonLoader from "@components/loader/skeleton";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { paymentMethodText } from "@utils/payments";
import { theme } from "@styles/themes";
import { PaymentsRecord } from "@api/types";

const StyledSeeMore = styled.h1`
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  color: ${theme.colors.white.normal};
  text-decoration: underline;
`;

const ResponsiveDiv = styled.div`
  padding-right: 32px;

  @media (max-width: 1200px) and (min-width: 740px) {
    padding-right: 0;
  }
  @media (max-width: 739px) {
    padding-right: 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0;

  @media (max-width: 768px) {
    margin: 24px 0 0 0; /* Adjust margin for mobile */
  }
`;

const PaymentRecord = () => {
  // variables
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { data } = useGetPaymentSummary();
  const [visiblePayments, setVisiblePayments] = useState<PaymentsRecord[]>([]);
  const { control, watch } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const watchSearch = watch("search");

  // effects
  useEffect(() => {
    let list = isMobile
      ? data?.paymentsRecord?.slice(0, 3)
      : data?.paymentsRecord;

    if (watchSearch) {
      list = list?.filter((item) =>
        item?.paymentAmount?.toString().includes(watchSearch)
      );
    }

    setVisiblePayments(list);
  }, [watchSearch, data]);

  // returns
  return (
    <ResponsiveDiv>
      <StyledContainer>
        <div className="d-none d-md-block">
          <Heading
            fontSize="20px"
            title={t("payments.paymentRecord")}
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-block d-md-none">
          <Heading
            fontSize="17px"
            title={t("payments.paymentRecord")}
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </div>
        {visiblePayments?.length > 0 && (
          <div className="d-none d-md-block">
            <TextField
              placeholder={t("payments.search")}
              name="search"
              size="sm"
              control={control}
              endIcon={
                <Icon
                  icon="majesticons:search-line"
                  color="#BFBFBF"
                  width={24}
                />
              }
            />
          </div>
        )}
      </StyledContainer>
      {visiblePayments?.length <= 0 ? (
        <Heading
          fontSize="17px"
          title={t("payments.thereIsNoPayments")}
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      ) : (
        <>
        <Row className="g-0" style={{padding:"0 24px"}}>
          <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.credits")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.paymentMethod")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.date")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
            <Heading
              fontSize="14px"
              title={t("payments.state")}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col xl={1} lg={1} md={1} sm={1} className="d-none d-md-block"></Col>
        </Row>
         <div >
         {visiblePayments ? (
           visiblePayments?.map((elem, index) => (
             <PaymentDetailInfo
               key={index}
               amount={elem.paymentAmount}
               paymentMethod={paymentMethodText[elem.method]}
               timestamp={elem.uploadTimestamp}
               status={elem.status}
               invoiceUrl={elem.invoiceUrl}
             />
           ))
         ) : (
           <SkeletonLoader />
         )}

         {isMobile && visiblePayments && visiblePayments.length >3&& (
           <div className="my-3 mb-5">
             <StyledSeeMore onClick={() => navigate("records")}>
               {t("actions.seeMore")}
             </StyledSeeMore>
           </div>
         )}
       </div>
       </>
      )}
    </ResponsiveDiv>
  );
};

export default PaymentRecord;
