import { theme } from "@styles/themes";
import Heading from "@components/font/heading";
import React from "react";
import styled from "styled-components";

const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey.normal};
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const CardContainer = styled.div`
  display: flex;
  margin: 24px 0 80px 0;
  column-gap: 10px;
  height: 96px;
`;
const ActiveBookiesProfile = ({ data }) => {
  return (
    <CardContainer>
      <Card>
        <Heading
          title="profile.activeBookies"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normalActive}
        />
        <Heading
          title={data?.activeBookies}
          fontSize="24px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </Card>
      <Card>
        <Heading
          title="profile.activeProfiles"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normalActive}
        />
        <Heading
          title={data?.activeProfiles}
          fontSize="24px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </Card>
    </CardContainer>
  );
};

export default ActiveBookiesProfile;
