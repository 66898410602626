import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { theme } from "@styles/themes";

const ModalContainer = styled.div`
  background-color: #1c1c1c;
  padding: 0 24px;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 0.5px solid ${theme.colors.grey.lightActive};
  max-height: 90vh;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14.5px 0;
  position: sticky;
  top: 0;
  background-color: #1c1c1c;
  z-index: 1;
`;

const ContentContainer = styled.div`
  padding: 24px 0;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  border-radius: 4px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(28, 28, 28, 0.8);
`;

interface Secure3DModalProps {
  open: boolean;
  onClose: () => void;
  acsURL: string;
  creq: string;
  onComplete: () => void;
  onFailed: () => void;
}

const Secure3DModal: React.FC<Secure3DModalProps> = ({
  open,
  onClose,
  acsURL,
  creq,
  onComplete,
  onFailed,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (open) {
      formRef.current?.submit();
    }
  }, [open]);

  useEffect(() => {
    // Function to handle messages from the backend
    const handleMessage = (event: MessageEvent) => {
      console.log("Received message:", event.data);
      console.log("Url:", event.origin);

      // Verify the origin of the message
      // Replace 'your-backend-domain.com' with your actual backend domain
      if (event.origin === "https://api.inbetia.com") {
        try {
          const data = JSON.parse(event.data);
          console.log("data", data);
          if (data.success) {
            console.log("Payment complete:", data);
            onComplete();
          } else {
            onFailed();
          }
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onComplete, onClose]);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <Modal
      show={open}
      centered
      dialogClassName="modal-lg"
      onHide={onClose}
      onBackdropClick={onClose}
    >
      <ModalContainer>
        <HeaderContainer>
          <h2 className="text-xl font-bold text-white">
            3D Secure Verification
          </h2>
        </HeaderContainer>

        <ContentContainer>
          <IframeContainer>
            <form
              ref={formRef}
              action={acsURL}
              method="POST"
              target="secure3dframe"
            >
              <input type="hidden" name="creq" value={creq} />
            </form>

            <StyledIframe
              ref={iframeRef}
              name="secure3dframe"
              onLoad={handleIframeLoad}
              title="3D Secure Verification"
            />

            {isLoading && (
              <LoadingOverlay>
                <Spinner animation="border" size="sm" />
              </LoadingOverlay>
            )}
          </IframeContainer>
        </ContentContainer>
      </ModalContainer>
    </Modal>
  );
};

export default Secure3DModal;
