import React, { useEffect } from "react";

import CopyBox from "./components/copyBox";
import { usePayment } from "@context/PaymentContext";
import { paymentMethods } from "@utils/payments";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TextField } from "@components/form/textField";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";

const BankTransferDetail = () => {
  const { isPaymentSuccess, paymentMethod } = usePayment();
  const { t } = useTranslation();

  useEffect(() => {
    if (isPaymentSuccess && paymentMethods.bankTransfer === paymentMethod) {
      toast.success(t("success.bankTransfer"));
    }
  }, [isPaymentSuccess]);

  return (
    <div>
      <CopyBox title="payments.useConceptBelow" code="REF4958" />
      <CopyBox title="payments.accountTitular" code="ADJ SLU" />
      <CopyBox title="payments.bic" code="XXXWRWEHR" />
      <CopyBox title="payments.iban" code="ES23 3645 7568 8467 4957" />
      <CopyBox
        title="payments.namebankaddress"
        code={`Wise \n Rue du Trone 100, 3rd floor \n Brussels \n 1050`}
      />
      <div style={{ padding: "0 12px", marginTop: "12px" }}>
        <Heading
          fontSize="14px"
          title="payments.conceptDisclaimer"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>
    </div>
  );
};

export default BankTransferDetail;
