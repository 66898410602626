/* eslint-disable @typescript-eslint/no-empty-function */
// libraries
import { MyAccount, Settings } from "@api/types";
import { isUserAuthExpiring } from "@utils/datetime";
import {
  APP_AUTH_DATA,
  APP_AUTH_SETTING,
  APP_AUTH_TIME,
  APP_AUTH_TOKEN,
} from "@utils/keys";
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { usePayment } from "./PaymentContext";
import { AppCurrency, convertToCurrencyAmount } from "@utils/currency";

// types
interface DefaultValue {
  resetAuth: () => void;
  setAuth: (token: string) => void;
  setAccount: (data: any) => void;
  setSetting: (data: any) => void;
  convertToAppCurrency: (amount: number) => string;
  token: string;
  accountDetail: any;
  accountSetting: any;
  isLoading: boolean;
  initLoading: boolean;
  isUnauthorized: boolean;
  isRefreshTokenModalVisible: boolean;
  toggleRefreshTokenModal: () => void;
}

const defaultValue: DefaultValue = {
  setAuth: () => {},
  setSetting: () => {},
  setAccount: () => {},
  convertToAppCurrency: () => "0",
  token: "",
  accountDetail: "",
  accountSetting: "",
  resetAuth: () => {},
  isLoading: false,
  isUnauthorized: false,
  initLoading: true,
  isRefreshTokenModalVisible: false,
  toggleRefreshTokenModal: () => {},
};

// context
const AuthContext = createContext(defaultValue);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // auth variable
  const { i18n } = useTranslation();
  const [token, setToken] = useState("");
  const [accountDetail, setAccountDetail] = useState<MyAccount | null>(null);
  const [accountSetting, setAccountSetting] = useState<Settings | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const expiryInterval = useRef<NodeJS.Timeout>(null);
  const [isRefreshTokenModalVisible, toggleRefreshTokenModal] = useReducer(
    (prev) => !prev,
    false
  );

  // hooks
  useEffect(() => {
    updateLoggedInAuth();
    // if (token) {
    //   expiryInterval.current = setInterval(() => {
    //     const isExprining = isUserAuthExpiring();
    //     if (isExprining) {
    //       toggleRefreshTokenModal();
    //     }
    //   }, 1000 * 60);
    // } else if (expiryInterval.current) {
    //   clearInterval(expiryInterval.current);
    // }
  }, [token]);
  useEffect(() => {
    // Update token on mount
    updateLoggedInAuth();

    // Update account details and settings only if they're not set
    if (accountDetail === null) {
      updateAccountInAuth();
    }
    if (accountSetting === null) {
      updateSettingInAuth();
    }
  }, []);

  useEffect(() => {
    if (accountDetail === null) {
      updateAccountInAuth();
    }
  }, [accountDetail]);

  useEffect(() => {
    if (accountSetting === null) {
      updateSettingInAuth();
    }
  }, [accountSetting]);

  // functions
  const updateLoggedInAuth = async () => {
    const savedToken = localStorage.getItem(APP_AUTH_TOKEN);
    setToken(savedToken);
    setInitLoading(false);
  };

  const updateAccountInAuth = async () => {
    const accountSaved = localStorage.getItem(APP_AUTH_DATA);
    const parsedAccount = JSON.parse(accountSaved);
    // Only update if the value has changed
    if (
      parsedAccount &&
      JSON.stringify(parsedAccount) !== JSON.stringify(accountDetail)
    ) {
      setAccountDetail(parsedAccount);
    }
    setInitLoading(false);
  };

  const updateSettingInAuth = async () => {
    const accountSetting = localStorage.getItem(APP_AUTH_SETTING);
    const parsedSetting = JSON.parse(accountSetting);

    // Only update if the value has changed
    if (
      parsedSetting &&
      JSON.stringify(parsedSetting) !== JSON.stringify(accountSetting)
    ) {
      setAccountSetting(parsedSetting);
      parsedSetting.language && i18n.changeLanguage(parsedSetting.language);
    }
    setInitLoading(false);
  };

  const setAuth: DefaultValue["setAuth"] = async (token) => {
    const currentTime = new Date();
    localStorage.setItem(APP_AUTH_TIME, currentTime.getTime()?.toString());
    localStorage.setItem(APP_AUTH_TOKEN, token);
    setToken(token);
  };

  const setAccount = async (data?: MyAccount) => {
    localStorage.setItem(APP_AUTH_DATA, JSON.stringify(data));
    setAccountDetail(data);
  };

  const setSetting = async (data?: Settings) => {
    localStorage.setItem(APP_AUTH_SETTING, JSON.stringify(data));
    setAccountSetting(data);
  };

  const resetAuth = async () => {
    setToken("");
    setAccountDetail(null);
    setAccountSetting(null);
    localStorage.removeItem(APP_AUTH_TOKEN);
    localStorage.removeItem(APP_AUTH_DATA);
    localStorage.removeItem(APP_AUTH_SETTING);
  };

  // auth provider
  return (
    <AuthContext.Provider
      value={{
        token,
        setAuth,
        setAccount,
        setSetting,
        resetAuth,
        accountDetail,
        accountSetting,
        isLoading,
        isUnauthorized: !token,
        initLoading: initLoading,
        isRefreshTokenModalVisible,
        convertToAppCurrency: (amount) =>
          convertToCurrencyAmount(
            amount,
            (accountSetting?.currency as AppCurrency) || "eur"
          ),
        toggleRefreshTokenModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
