import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import { SwipeableListItem, Type } from "react-swipeable-list";
import { SwipeableList } from "react-swipeable-list";
import React, { useEffect, useRef, useState } from "react";
import { SwipeAction } from "react-swipeable-list";
import { TrailingActions } from "react-swipeable-list";
import styled from "styled-components";
import { useDeleteAllNotification } from "@api/useDeleteAllNotifications";
import ShowMore from "react-show-more";
import { useTranslation } from "react-i18next";
const Card = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) =>
    isToday ? theme.colors.grey.darkActive : theme.colors.grey.darker};
  border: ${({ isToday }) =>
    `1px solid ${
      isToday ? theme.colors.grey.darkActive : theme.colors.grey.normal
    }`};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: ${theme.colors.main.normal};
  border-radius: 50%;
`;

interface NotificationCardProps {
  title?: string;
  date?: string;
  content?: string;
  isIncidence?: string;
  notifyId?: string;
  isRead?: boolean;
}

const LeftActions = ({ onDelete }) => (
  <TrailingActions>
    <SwipeAction destructive={true} onClick={onDelete}>
      <div
        className="d-flex align-items-center"
        style={{ backgroundColor: theme.colors.red.lightActive }}
      >
        <Icon
          style={{
            fontSize: "24px",
            color: theme.colors.grey.darker,
            margin: "0 18px",
          }}
          icon="mingcute:delete-2-line"
        />
      </div>
    </SwipeAction>
  </TrailingActions>
);

const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  date,
  content,
  isIncidence,
  notifyId,
  isRead,
}) => {
  const { t } = useTranslation();
  const { mutate } = useDeleteAllNotification();
  const handleDelete = (notifyId: string) => {
    mutate(notifyId);
  };
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB");
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedYesterday = yesterday.toLocaleDateString("en-GB");

  return (
    <SwipeableList
      fullSwipe={false}
      type={Type.ANDROID}
      style={{ margin: "10px 0", borderRadius: "8px" }}
    >
      <SwipeableListItem
        listType={Type.IOS}
        fullSwipe={false}
        trailingActions={
          <LeftActions onDelete={() => handleDelete(notifyId)} />
        }
      >
        <Card isToday={!isRead}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {!isRead && <Dot />}
              <StyledTitle
                title={`${title} ${isIncidence ? "⚠️" : ""}`}
                fontSize="14px"
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <Heading
              title={
                date === formattedDate
                  ? "Today"
                  : date === formattedYesterday
                  ? "Yesterday"
                  : date
              }
              fontSize="12px"
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </div>
          <StyledHeadingSeeMore style={{fontSize:'14px'}}>
            
            <ShowMore
            
              lines={2}
              more={
                <Heading
                  title={t("actions.seeMore")}
                  fontSize="12px"
                  fontWeight="400"
                  color={theme.colors.white.dark}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                />
              }
              less={
                <Heading
                  title="See less"
                  fontSize="12px"
                  fontWeight="400"
                  color={theme.colors.white.dark}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                />
              }
            >
               <Heading
                  title={content}
                  fontSize="14px"
                  fontWeight="400"
                  color={theme.colors.white.dark}
                
                />
              
            </ShowMore>
          </StyledHeadingSeeMore>
        </Card>
      </SwipeableListItem>
    </SwipeableList>
  );
};


const StyledHeadingSeeMore = styled.div`
  font-size: "14px";
  font-weight:  "400";
  color:  ${theme.colors.white.dark};
  margin-bottom: 0;
  word-break: break-word;
  line-height: 24px;
`;

const StyledTitle = styled(Heading)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-height: 19px;
`;
export default NotificationCard;
