import { useConfirmSignup } from "@api/useConfirmSignup";
import {
  ForgotPasswordRequest,
  useForgotPassword,
} from "@api/useForgotPassword";
import { useLogin } from "@api/useLogin";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import AlertModal from "@components/modal/AlertModal";
import CopyModal from "@components/modal/CopyModal";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React, { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
const StyledContainer = styled.div`
  @media (max-width: 991px) {
    max-width: 627px;
    min-width: 90%;
  }

  @media (min-width: 990px) {
    max-width: 387px;
    min-width: 387px;
  }
`;

type ForgotPasswordModalProps = {
  onSuccess?: () => void;
};

const ForgotPasswordModal = ({ onSuccess }: ForgotPasswordModalProps) => {
  // variables
  const { t } = useTranslation();
  const { mutate: forgotPassword, isLoading } = useForgotPassword({
    onSuccess,
  });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordRequest>({
    defaultValues: {
      email: "",
    },
  });

  // functions
  const onSubmit = async (values: ForgotPasswordRequest) => {
    forgotPassword(values);
  };

  // returns
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="email"
        onChange={()=>trigger('email')}
        bgColor={theme.colors.grey.dark}
        size="sm"
        placeholder="addEditUser.emailLabel"
        errors={errors.email}
        rules={{
          required: t("errors.required"),
          pattern: {
            value: regexPatterns?.regEmail,
            message: t("errors.emailPattern"),
          },
        }}
      />
      <Heading
        style={{ marginTop: "15px" }}
        title="login.checkEmail"
        fontSize="14px"
        fontWeight="400"
        color={theme.colors.white.normal}
      />

      <div
        className="justify-content-end d-flex"
        style={{ paddingTop: "24px" }}
      >
        <Button
          type="submit"
          title="actions.send"
          style={{  
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
          }}
          isLoading={isSubmitting || isLoading}
        />
      </div>
    </form>
  );
};

const LoginSection = () => {
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [forgotPasswordOpen, setForgotPasswordOpen] =
    React.useState<boolean>(false);
  const [isEmailSentVisble, toggleEmailSentAlert] = useReducer(
    (prev) => !prev,
    false
  );
  const [isConfirmSignupVisble, toggleConfirmSignupAlert] = useReducer(
    (prev) => !prev,
    false
  );

  const { mutate: confirmSignup } = useConfirmSignup({
    onSuccess: () => {
      toggleConfirmSignupAlert();
    },
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const { mutate, isLoading } = useLogin({
    onError: (error) => {
      if (error.code === "ERR_NETWORK") {
        toast.error(t("errors.badconnection"));
      } else if (error.response.status === 401) {
        setLoginError(true);
        // toast.error(t("login.errorsLogin.baduserpass"));
      } else {
        toast.error(t("errors.unknowError"));
      }
    },
  });

  useEffect(() => {
    if (token) {
      confirmSignup(token);
    }
  }, [token]);

  const onSubmit = async (values: any) => {
    await mutate(values);
  };

  const onEmailSendSuccess = () => {
    toggleEmailSentAlert();
    setForgotPasswordOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh", // Ensures full height of the viewport
        backgroundColor: theme.colors.grey.darker, // Background color (optional)
      }}
    >
      <StyledContainer>
        <Heading
          className="d-none d-sm-block d-lg-none"
          title="login.readyForNewEra"
          fontSize="29px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            control={control}
            className="mt-4 mb-4"
            name="username"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="addEditUser.usernameLabel"
            errors={errors.username}
            rules={{ required: t("errors.required") }}
          />
          <TextField
            control={control}
            name="password"
            type="password"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="addEditUser.passwordLabel"
            errors={errors.password}
            rules={{ required: t("errors.required") }}
          />
          <div
            className="d-flex mb-3 mt-3"
            onClick={() => setForgotPasswordOpen(true)}
          >
            <Heading
              title="login.forgotPassword"
              fontSize="14px"
              fontWeight="400"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              color={theme.colors.white.normal}
            />
          </div>
          <Button
            title="signIn.signInButton"
            type="submit"
            isLoading={isLoading}
            style={{
              width: "100%",
              fontSize: "14px",
              fontWeight: "700",
              borderRadius: "8px",
            }}
          />
          <Heading
            title="login.dontHaveAccount"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.normal}
            className="mt-3 mb-2"
          />
        </form>
        <Button
          title="signIn.signUpButton" 
          outline
          onClick={() => navigate("/register")}
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
          }}
        />
      </StyledContainer>
      <CopyModal
        open={forgotPasswordOpen}
        setOpen={setForgotPasswordOpen}
        heading="login.restorePassword"
        mainContentStyle={{ padding: "0" }}
        mainContent={<ForgotPasswordModal onSuccess={onEmailSendSuccess} />}
      />

      <AlertModal
        type="success"
        title={"login.emailSent"}
        description={t("login.checkEmail")}
        open={isEmailSentVisble}
        onActionClick={toggleEmailSentAlert}
      />
      <CopyModal
        open={loginError}
        setOpen={setLoginError}
        heading="signUp.weCoudntSignin"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <>
            <Heading
              title="signUp.emailPasswordIncorrect"
              fontSize="14px"
              fontWeight="400"
              style={{ padding: "24px 0" }}
              color={theme.colors.white.normal}
            />
            <div style={{ textAlign: "right" }}>
              <Button
                title="actions.tryAgain"
                onClick={() => setLoginError(false)}
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  borderRadius: "8px",
                }}
              />
            </div>
          </>
        }
      />

      <AlertModal
        type="success"
        title={"signUp.success"}
        description={t("signUp.confirmSignupDescription")}
        open={isConfirmSignupVisble}
        onActionClick={toggleConfirmSignupAlert}
      />
    </div>
  );
};

export default LoginSection;
