import Heading from "@components/font/heading";
import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "@components/button/button";
import { usePayment } from "@context/PaymentContext";
import { theme } from "@styles/themes";
import { paymentMethods } from "@utils/payments";
import { TabContext } from "@context/TabContext";
import { t } from "i18next";

const FooterWrapper = styled.div`
  position: fixed;
  border-top: 0.5px solid ${(props) => props.theme.colors.white.dark};
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
  padding: 10px 22px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalWrapper = styled.div`
  text-align: left;
`;

const StyledButton = styled(Button)`
  width: 45%;
  font-weight: 700;
  font-size: 14px;
`;

interface PaymentFooterProps {
  isSubmitting?: boolean;
  buttonTitle?: string;
  onClick?: () => void;
}

export const PaymentFooter: React.FC<PaymentFooterProps> = ({
  isSubmitting,
  buttonTitle = t("payments.continue"),
  onClick,
}) => {
  const {
    amount,
    convertToPaymentCurrency: convertToCurrencyAmount,
    paymentMethod,
  } = usePayment();
  const { activeTab } = useContext(TabContext);

  return (
    <FooterWrapper>
      <FooterContent>
        <TotalWrapper>
          <Heading
            title="Total"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          <Heading
            title={convertToCurrencyAmount(amount)}
            fontSize="20px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </TotalWrapper>

        <StyledButton
          title={buttonTitle}
          isLoading={isSubmitting}
          type="submit"
          disabled={
            paymentMethod === paymentMethods.creditCard && activeTab === 1
          }
          onClick={onClick}
        />
      </FooterContent>
    </FooterWrapper>
  );
};
