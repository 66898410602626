// libraries
import React from "react";
import { Control } from "react-hook-form";

// components
import { TextField } from "@components/form/textField";
import test from "@assets/icons/caret.svg";
import { GetCreditFormType } from "../../section/paymentSection/types";
import { usePayment } from "@context/PaymentContext";
import { useTranslation } from "react-i18next";

type YouPayYouGetProps = {
  bgColor: string;
  control: Control<GetCreditFormType, any>;
};

const YouPayYouGet = ({ bgColor, control }: YouPayYouGetProps) => {
  // variables
  const { credit } = usePayment();
  const { t } = useTranslation();

  // returns
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <TextField
        placeholder="0"
        name="you_pay"
        control={control}
        size="lg"
        labelText={t("paymentBox.youpay")}
        bgColor={bgColor}
        rules={{
          required: t("errors.required"),
          min: {
            value: 20,
            message: t("errors.minAmount", { amount: 20 }),
          },
        }}
      />
      <img
        src={test}
        style={{
          rotate: "-90deg",
          margin: "22px 6px 0 6px",
          width: 24,
          height: 24,
        }}
      />
      <TextField
        placeholder="0"
        disabled
        name="you_get"
        control={control}
        size="lg"
        labelText={t("paymentBox.youget")}
        bgColor={bgColor}
        value={credit || 0}
        rules={{
          required: t("errors.required"),
        }}
      />
    </div>
  );
};

export default YouPayYouGet;
