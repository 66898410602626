import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import BookieRecordSection from "./profileBookie/bookieRecord";
import { Bookie } from "@api/useUpdateBookiesMultiple";
import styled from "styled-components";

// Scrollable container for the Offcanvas body with custom scrollbar
const ScrollableBody = styled.div`
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${theme.colors.grey.darker};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.grey.dark};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.colors.grey.normal};
  }
`;

const DrawerRecord = ({
  setShow,
  show,
  bookie,
}: {
  setShow: (value: boolean) => void;
  show: boolean;
  bookie: Bookie;
}) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{
          background: theme.colors.grey.darker,
          borderLeft: `0.5px solid ${theme.colors.white.dark}`,
        }}
      >
        <Offcanvas.Header
          className="pt-2 pb-2"
          style={{
            borderBottom: `0.5px solid ${theme.colors.white.dark}`,
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ padding: "6px 8px" }}
          >
            <div>
              <Heading
                fontSize="20px"
                title={bookie.bookie}
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <div>
              <Icon
                onClick={handleClose}
                style={{ color: theme.colors.white.normal }}
                icon="bitcoin-icons:cross-filled"
                width="24"
                height="24"
              />
            </div>
          </div>
        </Offcanvas.Header>
        <ScrollableBody>
          <Offcanvas.Body>
            <BookieRecordSection bookie={bookie} />
          </Offcanvas.Body>
        </ScrollableBody>
      </Offcanvas>
    </>
  );
};

export default DrawerRecord;
