// libraries
import { useInfiniteQuery } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse, BookieRecord } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

export type GetBookiesRecordResponse = ServerResponse<{
  bets: BookieRecord[];
  items: number;
  pages: number;
}>;

export const getBookiesRecordQKey = "bookiesRecord";

export const useGetBookiesRecord = ({
  bookieName,
  accountId,
  page,
  onSuccess,
  onError,
}: {
  bookieName: string;
  accountId: string;
  page: number;
  onSuccess?: (res: BookieRecord[] | []) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
}) => {
  const { triggerError } = useErrorHandler();

  // Updated to use infinite query
  const query = useInfiniteQuery<BookieRecord[], ServerResponse<unknown>>(
    [getBookiesRecordQKey, bookieName, accountId],
    async ({ pageParam = 1 }) => {
      try {
        const bookiesRecordData = await request<GetBookiesRecordResponse>({
          method: "get",
          url: `/bookies/bets?bookieName=${bookieName}&accountId=${accountId}&page=${pageParam}`,
        });

        const data = bookiesRecordData.data.data;
        onSuccess && onSuccess(data.bets);
        return data.bets;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // If the last page is empty, return undefined to indicate no more pages
        if (!lastPage || lastPage.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    }
  );

  return query;
};
