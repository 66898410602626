import { Country } from "country-state-city";

const excludeCountries = [
  "Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", "Andorra",
  "Angola", "Anguilla", "Antarctica", "Antigua And Barbuda", "Aruba", "Azerbaijan",
  "The Bahamas", "Barbados", "Bhutan", "Belize", "Benin", "Bermuda",
  "Botswana", "Bouvet Island", "British Indian Ocean Territory", "Brunei", "Burkina Faso", "Burundi",
  "Cayman Islands", "Chad", "Christmas Island", "Central African Republic", "Cocos (Keeling) Islands", "Comoros",
  "Congo (Congo-Brazzaville)", "Congo", "Cook Islands", "Cuba", "Curaçao", "Democratic Republic of the Congo",
  "Djibouti", "East Timor", "Falkland Islands", "Faroe Islands", "Fiji Islands", "French Polynesia",
  "French Guiana", "French Southern Territories", "Ghana", "Iran", "Iraq", "Israel",
  "Kazakhstan", "Kosovo", "Kyrgyzstan", "Lebanon", "Lesotho", "Liberia",
  "Libya", "Montenegro", "Myanmar (formerly Burma)", "North Korea", "Sint Maarten (Dutch part)", "Saint-Martin (French part)",
  "Solomon Islands", "Somalia", "Svalbard And Jan Mayen Islands", "Syria", "Tokelau", "Togo",
  "Tonga", "Trinidad And Tobago", "Turks And Caicos Islands", "Tuvalu", "United States Minor Outlying Islands", "Uruguay",
  "Uzbekistan", "Vanuatu", "Vatican City State (Holy See)", "Venezuela", "Vietnam", "Virgin Islands (US)",
  "Virgin Islands (British)", "Wallis And Futuna Islands", "Western Sahara", "Yemen", "Zambia", "Zimbabwe",
  "Man (Isle of)", "Martinica", "Macau S.A.R.", "South Sudan", "Guernsey and Alderney", "Guinea",
  "Heard Island and McDonald Islands", "Suriname", "Sudan", "Cape Verde", "Equatorial Guinea", "Eritrea",
  "Cote D'Ivoire (Ivory Coast)", "Gabon", "The Gambia", "Guadeloupe", "Guam", "Guinea-Bissau",
  "Guyana", "Haiti", "Maldives", "Mali", "Bonaire, Sint Eustatius and Saba", "Palestinian Territory Occupied",
  "Seychelles", "Sierra Leone", "Jersey", "Sao Tome and Principe", "Samoa", "Cameroon",
  "Kiribati", "Kenya", "Saint-Barthelemy", "Senegal", "Marshall Islands", "Martinica",
  "Saint Vincent And The Grenadines", "Rwanda", "Saint Helena", "Saint Kitts And Nevis", "Saint Lucia", "Saint Pierre and Miquelon",
  "Pitcairn Island", "Niger", "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands",
  "New Caledonia", "Nepal", "Nauru", "Madagascar", "Malawi", "Martinique",
  "Mauritania", "Mauritius", "Mayotte", "Mongolia", "Montserrat", "Mozambique",
  "Myanmar", "Namibia", "Papua New Guinea", "Palau", "Reunion", "Oman",
  "Micronesia", "Turkmenistan", "Uganda", "Sri Lanka"
];

export const appCountries = Country.getAllCountries().filter((country) => {
  return !excludeCountries.includes(country.name);
});

export const countryCodes = appCountries?.map((country: any) => ({
  label: country.phonecode,
  value: country.phonecode,
  flag: country.isoCode,
}));

export const languages = {
  eng: {
    value: "EN",
    label: "English",
  },
  esp: {
    value: "ES",
    label: "Español",
  },
};

export const TIME_ZONES = [
  { value: "Pacific/Kwajalein", label: "UTC+12 - Kwajalein" },    // UTC+12
  { value: "Pacific/Auckland", label: "NZST - Auckland" },         // UTC+12
  { value: "Pacific/Fiji", label: "FJT - Fiji" },                  // UTC+12
  { value: "Pacific/Noumea", label: "UTC+11 - Noumea" },           // UTC+11
  { value: "Australia/Sydney", label: "AEST - Sydney" },           // UTC+10
  { value: "Pacific/Guam", label: "ChST - Guam" },                 // UTC+10
  { value: "Pacific/Port_Moresby", label: "PGT - Port Moresby" },  // UTC+10
  { value: "Asia/Tokyo", label: "JST - Tokyo" },                   // UTC+9
  { value: "Asia/Seoul", label: "KST - Seoul" },                   // UTC+9
  { value: "Asia/Shanghai", label: "CST - Shanghai" },             // UTC+8
  { value: "Asia/Beijing", label: "CST - Beijing" },               // UTC+8
  { value: "Asia/Hong_Kong", label: "HKT - Hong Kong" },           // UTC+8
  { value: "Asia/Dubai", label: "GST - Dubai" },                   // UTC+4
  { value: "Asia/Kolkata", label: "IST - Kolkata" },               // UTC+5:30
  { value: "Europe/Moscow", label: "MSK - Moscow" },               // UTC+3
  { value: "Africa/Cairo", label: "EET - Cairo" },                 // UTC+2
  { value: "Africa/Johannesburg", label: "SAST - Johannesburg" },  // UTC+2
  { value: "Europe/Madrid", label: "CET - Madrid" },               // UTC+1
  { value: "Europe/Paris", label: "CET - Paris" },                 // UTC+1
  { value: "Atlantic/Canary", label: "WET - Canary Islands" },     // UTC+0
  { value: "Europe/London", label: "GMT - London" },               // UTC+0
  { value: "America/Sao_Paulo", label: "BRT - São Paulo" },        // UTC-3
  { value: "America/Caracas", label: "VET - Caracas" },            // UTC-4
  { value: "America/New_York", label: "ET - New York" },           // UTC-5
  { value: "America/Chicago", label: "CT - Chicago" },             // UTC-6
  { value: "America/Denver", label: "MT - Denver" },               // UTC-7
  { value: "America/Los_Angeles", label: "PT - Los Angeles" },     // UTC-8
  { value: "America/Adak", label: "HST - Hawaii" },                // UTC-10
  { value: "Pacific/Tahiti", label: "TAHT - Tahiti" },             // UTC-10
  { value: "Pacific/Apia", label: "SST - Samoa" },                 // UTC-11
  { value: "Pacific/Baker", label: "BIT - Baker Island" },         // UTC-12
]