import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import BookiesChart from "./bookiesChart";
import BookieChartDetail from "./bookieChartDetail";
import styled from "styled-components";
import DesktopChartBookie from "./desktopChartBookie";
import DesktopBookieChartDetail from "./desktopBookieChartDetail";
import DesktopChartBets from "./desktopChartBets";
import DesktopBetsChartDetail from "./desktopBetsChartDetail";
import Head from "../profileSection/myDataSection/head";
import DrawerFilter from "./drawerFIlter";
import { useStatsChart } from "@context/StatsChartContext";
import { Loading } from "@components/loader/Loading";
import IconButtonHover from "@components/button/Iconbutton";
import filter from "@assets/icon/filter.svg";
const ChartContainer = styled.div`
  padding: 24px;
  width: 871px;
  
  @media (max-width: 1200px) {
    display: none;
  }
`;
const HeadContainer = styled.div`
  margin: 24px 12px 0px 24px;

  @media (max-width: 1200px) {
    display: none;
  }
`;
const CustomLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const DesktopChartSection = ({ setOpenFilter, setActiveTab }) => {
  const { isLoading, clearFilters, getActiveFilters } = useStatsChart();
  const handleBookieFilterClear = () => {
    clearFilters("bookies");
  };
  const handleBetsFilterClear = () => {
    clearFilters("bets");
  };

  return (
    <>
      <HeadContainer>
        <Head title="profile.stats" backBtn={false} />
      </HeadContainer>
      {isLoading ? (
        <CustomLoading>
          <Loading />
        </CustomLoading>
      ) : (
        <ChartContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 24,
              paddingBottom: 8,
              borderBottom: `1px solid ${theme.colors.grey.dark}`,
            }}
          >
            <Heading
              title="profile.bookies"
              fontSize="17px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 12,
              }}
            >
              {(getActiveFilters("bookies").bookies.length > 0 ||
                getActiveFilters("bookies").profiles.length > 0 ||
                getActiveFilters("bookies").sports.length > 0) && (
                <Heading
                  onClick={handleBookieFilterClear}
                  title="profile.cleanFilters"
                  fontSize="14px"
                  fontWeight="400"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  color={theme.colors.white.normal}
                />
              )}
              <div style={{ position: "relative" }}>
                <IconButtonHover
                  iconStyle={{ width: "20px", height: "20px" }}
                  onClick={() => {
                    setOpenFilter(true);
                    setActiveTab("bookies");
                  }}
                  src={filter}
                  alt="verticalDots"
                />
                {(getActiveFilters("bookies").bookies.length > 0 ||
                  getActiveFilters("bookies").profiles.length > 0 ||
                  getActiveFilters("bookies").sports.length > 0) && (
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      background: theme.colors.red.normal,
                      borderRadius: "50%",
                      position: "absolute",
                      top: 2,
                      right: 0,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 24,
              padding: "16px",
              backgroundColor: theme.colors.grey.darkActive,
              borderRadius: 10,
            }}
          >
            <DesktopChartBookie filterType="bookies" />
            <DesktopBookieChartDetail filterType="bookies" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 24,
              marginBottom: 24,
              paddingBottom: 8,
              borderBottom: `1px solid ${theme.colors.grey.dark}`,
            }}
          >
            <Heading
              title="profile.bets"
              fontSize="17px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 12,
              }}
            >
              {(getActiveFilters("bets").sports.length > 0 ||
                getActiveFilters("bets").profiles.length > 0 ||
                getActiveFilters("bets").bookies.length > 0) && (
                <Heading
                  onClick={handleBetsFilterClear}
                  title="profile.cleanFilters"
                  fontSize="14px"
                  fontWeight="400"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  color={theme.colors.white.normal}
                />
              )}
              <div style={{ position: "relative" }}>
                <IconButtonHover
                  iconStyle={{ width: "20px", height: "20px" }}
                  onClick={() => {
                    setOpenFilter(true);
                    setActiveTab("bets");
                  }}
                  src={filter}
                  alt="verticalDots"
                />
                {(getActiveFilters("bets").sports.length > 0 ||
                  getActiveFilters("bets").profiles.length > 0 ||
                  getActiveFilters("bets").bookies.length > 0) && (
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      background: theme.colors.red.normal,
                      borderRadius: "50%",
                      position: "absolute",
                      top: 2,
                      right: 0,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 24,
              padding: "16px",
              backgroundColor: theme.colors.grey.darkActive,
              borderRadius: 10,
            }}
          >
            <DesktopChartBets filterType="bets" />
            <DesktopBetsChartDetail filterType="bets" />
          </div>
        </ChartContainer>
      )}
    </>
  );
};

export default DesktopChartSection;
