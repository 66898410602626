import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { useGetProfiles } from "@api/useGetProfiles";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TabContainer = styled.div`
  display: flex;
  gap: 4px;
  background-color: ${theme.colors.grey.darker};
  border-radius: 30px;
  padding: 4px;
  border: 1px solid ${theme.colors.grey.dark};
  width: max-content;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 0px 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${(props) =>
    props.active ? theme.colors.grey.dark : "transparent"};
  color: ${(props) =>
    props.active ? theme.colors.white.normal : theme.colors.white.normalActive};
  font-size: 12px;
`;
const ProfitTab = ({
  activeTab,
  setActiveTab,
  filteredProfit,
  filteredProfitDif,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: "24px 0" }}>
      <Heading
        title="profile.profit"
        fontSize="16px"
        fontWeight="400"
        color={theme.colors.white.normalActive}
      />
      <div className="d-flex align-items-center">
        <Heading
          title={`${filteredProfit.toFixed(2)}€`}
          fontSize="24px"
          fontWeight="700"
          style={{ margin: "16px 0" }}
          color={theme.colors.white.normal}
        />
        {filteredProfitDif != null && (
          <div
            style={{
              backgroundColor: theme.colors.grey.dark,
              borderRadius: "16px",
              marginLeft: "10px",
              padding: "2px 12px",
            }}
          >
            <Heading
              title={`${filteredProfitDif.toFixed(2)}%`}
              fontSize="14px"
              fontWeight="700"
              color={filteredProfitDif < 0 ? theme.colors.red.normal : theme.colors.green.normal}
            />
          </div>
        )}
      </div>
      <TabContainer>
        {[t("dashboard.all"), t("dashboard.oneday"), t("dashboard.oneweek"), t("dashboard.onemonth")].map((tab, index) => (
          <Tab
            key={tab}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            <Heading
              title={tab}
              fontSize="12px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </Tab>
        ))}
      </TabContainer>
    </div>
  );
};

export default ProfitTab;
