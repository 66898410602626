import { theme } from "@styles/themes";
import styled from "styled-components";
import React from "react";

export const IconButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    // background-color: ${theme.colors.grey.darkHover};
  }
`;
export const IconButtonContainer = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    background-color: ${theme.colors.grey.darkHover};
  }
`;

export const IconButtonHoverDiv = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    // background-color: ${theme.colors.grey.darkHover};
  }
`;
const IconButtonHover = ({
  src,
  style,
  onClick,
  alt,
  iconStyle
}: {
  src?: string;
  style?: any;
  onClick?: any;
  alt?: string;
  iconStyle?: any;
}) => {
  return (
    <IconButtonHoverDiv>
      <IconButtonContainer style={style} onClick={onClick}>
        <IconButton src={src} alt={alt} style={iconStyle}/>
      </IconButtonContainer>
    </IconButtonHoverDiv>
  );
};

export default IconButtonHover;
