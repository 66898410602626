// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";

const resources = {
  EN: {
    translation: translationEN,
  },
  ES: {
    translation: translationES,
  },
};

const savedLanguage = localStorage.getItem("userLanguage");
const defaultLanguage = savedLanguage 
  ? savedLanguage 
  : navigator.language.split("-")[0].toUpperCase();

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage.toUpperCase(), // Set the user's language as the default language
  fallbackLng: "EN", // Fallback to English if translation not available for user's language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
