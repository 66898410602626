import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Chip from "@components/chip";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import styled from "styled-components";
import { paymentStatusText } from "@utils/payments";
import { useSettings } from "@context/SettingsContext";
import { PaymentsRecord } from "@api/types";
import { usePayment } from "@context/PaymentContext";
import { useAuth } from "@context/AuthContext";
import IconButtonHover from "@components/button/Iconbutton";
import download from '@assets/icon/download.svg'
const SubHeading = styled.h1`
  font-size: 14px; /* Default font size */
  font-weight: 400;
  display: none;
  color: ${(props) => props.theme.colors.white.normal}; /* Default color */

  /* Mobile view styles */
  @media (max-width: 768px) {
    font-size: 12px;
    display: block;
    font-weight: 400;
    color: ${(props) => props.theme.colors.white.dark};
  }
`;

const CustomRow = styled(Row)`
  align-items: center;
  background-color: ${theme.colors.grey.dark};
  border-radius: 8px;
  padding: 8px 24px;
  min-height: 56px;
  margin: 16px 0px;

  @media (max-width: 767px) {
    background-color: transparent;
    border-radius: 0px;
     padding: 10px 10px;
    border-bottom: 0.5px solid ${theme.colors.grey.normal};
  }
`;
interface PaymentDetailInfoProps extends Pick<PaymentsRecord, "invoiceUrl"> {
  amount?: number; // Optional: Amount can be a number
  paymentMethod?: string; // Optional: Payment method as a string
  timestamp?: any; // Optional: Timestamp as a string
  status?: number; // Optional: Status as a string
  chipBg?: string; // Optional: Background color for the chip
}

const PaymentDetailInfo: React.FC<PaymentDetailInfoProps> = ({
  amount,
  paymentMethod,
  status,
  chipBg,
  invoiceUrl,
  timestamp,
}) => {
  const { formatTimestamp } = useSettings();
  const { convertToAppCurrency } = useAuth();

  return (
    <div style={{ margin: "14px 0px" }}>
      <CustomRow className="g-0">
        <Col xs={3} sm={4} md={3}>
          <Heading
            fontSize="14px"
            title={convertToAppCurrency(amount)}
            fontWeight="700"
            color={theme.colors.white.normal}
          />
          <SubHeading>{paymentMethod}</SubHeading>
        </Col>
        <Col md={3} className="d-none d-md-block">
          <Heading
            fontSize="14px"
            title={paymentMethod}
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </Col>
        <Col xs={3} sm={3} md={2}>
          <Heading
            fontSize="14px"
            title={formatTimestamp(timestamp, "date")}
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </Col>
        <Col xs={4} sm={4} md={3}>
          <Chip
            title={paymentStatusText[status]}
            color={
              status === 0
                ? theme.colors.yellow.normal
                : status === 1
                ? theme.colors.green.normal
                : theme.colors.red.normal
            }
            bgColor={chipBg}
          />
        </Col>
        <Col xs={2} sm={1} md={1} style={{textAlign:'end'}}>
          {!!invoiceUrl && (
            <IconButtonHover
            iconStyle={{ width: "20px", height: "20px" }}
            src={download}
            alt="cross"
            onClick={() => window.open(invoiceUrl, "_blank")}

          />
           
          )}
        </Col>
      </CustomRow>
    </div>
  );
};

export default PaymentDetailInfo;
