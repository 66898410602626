import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import BookieCard from "./bookieCard";
import { useGetBookiesRecord } from "@api/useGetBookiesRecord";
import { Bookie } from "@api/useUpdateBookiesMultiple";
import { Loading } from "@components/loader/Loading";
import { useTranslation } from "react-i18next";
import { theme } from "@styles/themes";

const BookieRecordSection: React.FC<{ bookie: Bookie }> = ({ bookie }) => {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetBookiesRecord({
      bookieName: bookie.bookie,
      accountId: bookie.accountId,
      page: 1, // Initial page, will be managed by infinite query
    });

  useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage && data?.pages[0]?.length > 0) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage, data]);

  if (!data || isLoading) return <Loading />;
  
  // Show message when no data is available
  if (data.pages[0]?.length === 0) {
    return <div style={{ padding: "10px", color:theme.colors.white.normal}}>{t("profile.nohistoricaldata")}</div>;
  }
  
  return (
    <div style={{ padding: "10px" }}>
      {data.pages.map((page, pageIndex) => (
        <React.Fragment key={pageIndex}>
          {page.map((elem, index) => (
            <BookieCard key={`${pageIndex}-${index}`} {...elem} />
          ))}
        </React.Fragment>
      ))}

      {/* Loading trigger element */}
      <div ref={ref} style={{ height: "20px", marginBottom: "20px" }}>
        {isFetchingNextPage ? <Loading /> : ""}
      </div>
    </div>
  );
};

export default BookieRecordSection;
