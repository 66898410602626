import React from "react";
import styled from "styled-components";
import { theme } from "@styles/themes";

export const LoadingInside = styled.div`
  width: 30px; /* Width of the loader */
  height: 30px; /* Height of the loader */
  border-radius: 50%; /* Make it circular */
  border: 4px solid ${theme.colors.main.normal}; /* Set the border to transparent */
  border-top: 4px solid transparent; /* Prevent overflow on top */
  background: transparent; /* Make background transparent */
  animation: spin 1s linear infinite; /* Spin animation */
  box-sizing: border-box; /* Include border in width and height calculation */
  align-self: center;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <LoadingInside />
    </div>
  );
};
