import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { theme } from "@styles/themes";
import styled from "styled-components";
import Heading from "@components/font/heading";
import { FilterType, useStatsChart } from "@context/StatsChartContext";
import { useAuth } from "@context/AuthContext";
import { Loading } from "@components/loader/Loading";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  border: 1px solid ${theme.colors.grey.normal};
  border-radius: 8px;
  padding: 0 16px;
  margin: 18px 0;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 228px;
  padding: 14px;

  height: 228px;
  flex-shrink: 0;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 24px 0;
  flex-grow: 1;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const LegendText = styled.span`
  color: ${theme.colors.white.normal};
  font-size: 14px;
`;

const BookiesChart = ({ filterType }: { filterType: FilterType }) => {
  const { getBookieStats, isLoading } = useStatsChart();
  const { convertToAppCurrency } = useAuth();
  const bookieStats = getBookieStats(filterType);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <Loading />
      </div>
    );
  }
  const data: ChartData<"doughnut"> = {
    labels: [],
    datasets: [
      {
        data: bookieStats?.stats.map((stat) => stat.totalProfit),
        backgroundColor: bookieStats?.stats.map((stat) => stat.color),
        borderColor: bookieStats?.stats.map((stat) => stat.color),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "70%",
  };

  return (
    <Container>
      <ChartContainer>
        <Pie data={data as any} options={options} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <Heading
            title="profile.profit"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          {bookieStats?.totalProfit && (
            <Heading
              title={convertToAppCurrency(bookieStats?.totalProfit)}
              fontSize="14px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
          )}
          <Heading
            title="100%"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </div>
      </ChartContainer>

      <LegendContainer>
        {bookieStats?.stats?.map((item, index) => (
          <LegendItem key={index}>
            <LegendDot color={item.color} />
            <LegendText>{item.bookie} </LegendText>
          </LegendItem>
        ))}
      </LegendContainer>
    </Container>
  );
};

export default BookiesChart;
