import { theme } from "@styles/themes";
import styled from "styled-components";

export const StyledContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${theme.colors.grey.normal};
  width: 100%;
  overflow: hidden;
  margin: 16px 0;
`;
export const StyleContent = styled.div`
  background-color: ${theme.colors.grey.darker};
  width: 100%;
  padding: 8px 16px;
`;
export const StyledHeader = styled.div`
  background-color: ${theme.colors.grey.dark};
  padding: 8px 16px;
  width: 100%;
`;
export const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const StyledChip = styled.div`
  width: auto;
  background-color: ${theme.colors.grey.darker};
  border-radius: 10px;
  padding: 2px 10px;
`;
export const TitleContainer = styled.div`
  width: max-content;
  margin: 8px 0px;
`;
export const StyleFooter = styled.div`
  padding: 8px 16px;
  border-top: 1px solid ${theme.colors.grey.normal};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
