import React, { FC } from "react";
import {
  Controller,
  Control,
  FieldValues,
  FieldError,
  Path,
  PathValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "@styles/themes";

// Styled-components for layout

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Label = styled.label<{ disabled?: boolean }>`
  margin-left: 8px;
  margin-bottom: 2px;
  font-size: 14px;
  cursor: pointer;
  color: ${(props) =>
    props.disabled ? theme.colors.white.darkActive : theme.colors.white.normal};
`;

const CheckboxIcon = styled.input.attrs<{ error?: boolean }>({
  type: "checkbox",
})`
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  ${(props) => props.error && `border-color: ${theme.colors.red.normal};`}

    &:disabled {
    cursor: not-allowed;
    border-color: ${theme.colors.white.darkActive}; /* Disabled border color */
    opacity: 0.6; /* Optional: Make it look grayed out */
  }

`;

const ErrorMessage = styled.div`
  color: ${theme.colors.red.normal};
  font-size: 12px;
  margin-top: 2px;
`;

// Define interface for the props
interface CheckboxFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  rules?: any;
  defaultValue?: PathValue<T, Path<T>>;
  errors?: FieldError;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  watch?: any;
}

// Main CheckboxField component
export const CheckboxField = <T extends FieldValues>({
  control,
  name,
  label,
  rules,
  defaultValue = false as any,
  errors,
  disabled,
  style,
  className,
  watch
}: CheckboxFieldProps<T>) => {
  const { t } = useTranslation();
  const checkboxId = `checkbox-${label.replace(/\s+/g, "-").toLowerCase()}`; // Generate a unique ID

  return (
    <div style={{ width: "100%", ...style }} className={className}>
      <CheckboxWrapper>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange: handleChange, value: fieldValue },
          }) => (
            <>
              <CheckboxIcon
                id={checkboxId}
                type="checkbox"
                checked={fieldValue}
                onChange={(e) => handleChange(e.target.checked)}
                error={!!errors}
                disabled={disabled}
              />
              <Label htmlFor={checkboxId} disabled={disabled}>{t(label)}</Label>
            </>
          )}
        />
      </CheckboxWrapper>

      {errors && (
        <ErrorMessage>{t(errors.message) || t("errors.required")}</ErrorMessage>
      )}
    </div>
  );
};
