import Heading from "@components/font/heading";
import { usePayment } from "@context/PaymentContext";
import { theme } from "@styles/themes";
import { paymentMethods, paymentMethodText } from "@utils/payments";
import React from "react";
import { useTranslation } from "react-i18next";
import { PaymentFooter } from "./components/paymentFooter";
import CopyModal from "@components/modal/CopyModal";
import BankTransferDetail from "./bankTranferDetail";

const StepThree = ({ handleClick }) => {
  const { t } = useTranslation();
  const { paymentMethod, currency, credit, billingDetails } = usePayment();

  // Log the filtered address or handle it as needed
  return (
    <div>
      <div
        className="my-4 d-block d-md-none"
        style={{
          backgroundColor: theme.colors.grey.dark,
          padding: "16px",
          borderRadius: "8px",
        }}
      >
        <Heading
          title={`${credit} ${t("payments.credits")}`}
          fontSize="24px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>

      <div>
        <Heading
          title={(t("profile.currency"))}
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          title={currency.toUpperCase()}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <div className="mt-4">
        <Heading
          title={(t("profile.billingDetails"))}
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          style={{ width: "60%" }}
          title={`${billingDetails?.name} ${billingDetails?.surname} 
            ${billingDetails?.address}, ${billingDetails?.addressExtra},
          ${billingDetails?.city} (${billingDetails?.country})
          `}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <div className="mt-4">
        <Heading
          title={(t("profile.paymentMethod"))}
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          style={{ width: "60%" }}
          title={paymentMethodText[paymentMethod]}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <PaymentFooter
        buttonTitle={
          paymentMethod === paymentMethods.bankTransfer
            ? t("payments.seeBankDetails")
            : t("payments.pay")
        }
        onClick={handleClick}
      />
    </div>
  );
};

export default StepThree;
