// libraries
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

// misc
import request from "@utils/request";
import { AuthResponse, ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { getAddressesQKey } from "./useGetAddress";

export type AddAddressResponse = AuthResponse;

export interface AddAddressRequest {
  address: string;
  addressExtra?: string;
  city: string;
  country: string;
  isCompany: boolean;
  isDefault?: boolean;
  name: string;
  surname: string;
  zipCode?: string;
  nif?: string;
  vat?: string;
  VAT?: string;
  isSaved?: boolean;
  companyName?: string;
}

export const useAddAddress = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: AddAddressResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const queryClient = useQueryClient();

  // request
  const query = useMutation<
    AddAddressResponse,
    ServerResponse<unknown> | undefined,
    AddAddressRequest
  >(["addAddress"], async (data: AddAddressRequest) => {
    try {
      const formData = new FormData();

      // Object.entries(data).forEach(([key, value]) => {
      //   formData.append(key, value);
      // });

      const res = await request<AuthResponse>({
        method: "post",
        url: "billing/addresses",
        data: data,
      });

      queryClient.invalidateQueries(getAddressesQKey);
      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};
