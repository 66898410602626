import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StepFour = ({ control, setValue, getValues, trigger, errors }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="g-2">
        <Col md={12} sm={12} xs={12}>
          <TextField
            control={control}
            name="telegramUser"
            defaultValue="" // Start with an empty value for the placeholder
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="@Telegram" // Placeholder remains visible when the field is empty
            onBlur={() => {
              // Safeguard: Ensure getValues exists before calling it
              if (getValues) {
                const value = getValues("telegramUser") || "";
                if (value && !value.startsWith("@")) {
                  setValue("telegramUser", `@${value}`); // Prepend "@" if missing
                }
              }
              trigger("telegramUser");
            }}
            onChange={(e) => {
              const value = e.target.value || "";
              // Ensure value always starts with "@"
              if (!value.startsWith("@") && value.length > 0) {
                setValue("telegramUser", `@${value}`); // Dynamically add "@" if missing
              } else {
                setValue("telegramUser", value); // Allow normal typing
              }
            }}
            errors={errors?.telegramUser} // Safeguard against undefined errors
            rules={{
              required: false,
              pattern: {
                value: regexPatterns?.regTelegram, // Ensure the regex allows valid Telegram usernames
                message: t("errors.telegramPattern"),
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepFour;
