import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import React from "react";
import { useForm } from "react-hook-form";


interface ArchieveMobileBookieProps {
  setArchieveBookieOpen: (open: boolean) => void;
  handleArchieveBtn?: () => void;
  isLoading?:boolean
}

const ArchieveMobileBookieModal = ({ setArchieveBookieOpen, handleArchieveBtn,isLoading }: ArchieveMobileBookieProps) => {
  return (
    <div>
      <Heading
        title="profile.bookieModalArchieveDes"
        fontSize="14px"
        fontWeight="400"
        color={theme.colors.white.normal}
      />

      <div style={{ textAlign: "right", marginTop: "24px" }}>
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          outline
          onClick={() => setArchieveBookieOpen(false)}
          title="addEditUser.cancelButton"
          size="md"
          type='button'
        />
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
            marginLeft: "12px",
          }}
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleArchieveBtn}
          title="profile.archieve"
          type='submit'
          size="md"
        />
      </div>
    </div>
  );
};

export default ArchieveMobileBookieModal;
