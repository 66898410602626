import React from "react";
import BookieRecordSection from "../../../section/profileSection/profileBookie/bookieRecord";
import { useLocation } from "react-router-dom";

const BookieRecord = () => {
  const location = useLocation();
  const bookie = location.state?.bookie;

  return <BookieRecordSection bookie={bookie} />;
};

export default BookieRecord;
