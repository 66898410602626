import { useResetPassword } from "@api/useResetPassword";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import CopyModal from "@components/modal/CopyModal";
import { theme } from "@styles/themes";
import { regexPatterns } from "@utils/regex";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

type ResetPasswordForm = {
  password: string;
  confirmPassword: string;
};

const ForgotPasswordSection = () => {
  // variables
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const {
    mutate: resetPassword,
    isLoading: resetPasswordLoading,
    isSuccess: resetPasswordSuccess,
  } = useResetPassword();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  // effects
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  // handlers
  const onSubmit = (values: ResetPasswordForm) => {
    resetPassword({
      password: values.password,
      token: token || "",
    });
  };

  // renders
  return (
    <div
      className="align-items-md-center align-items-start"
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh", // Ensures full height of the viewport
        backgroundColor: theme.colors.grey.darker, // Background color (optional)
      }}
    >
      <Container className="mb-3 mt-3">
        <Row className="g-2 mt-2 justify-content-center">
          <Col md={8} sm={12} xs={12}>
            <Heading
              title="login.restorePassword"
              fontSize="20px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
          </Col>
          <Col md={8} sm={12} xs={12}>
            <TextField
              control={control}
              name="password"
              onBlur={(e) => {
                trigger("password");
              }}
              bgColor={theme.colors.grey.dark}
              size="sm"
              type="password"
              placeholder={t("login.newPassword")}
              errors={errors.password}
              rules={{
                required: t("errors.required"),
                minLength: {
                  value: 6,
                  message: t("errors.passwordPattern"),
                },
                pattern: {
                  value: regexPatterns?.regPassword,
                  message: t("errors.passwordPattern"),
                },
              }}
            />
          </Col>
          <Col md={8} sm={12} xs={12}>
            <TextField
              control={control}
              name="confirmPassword"
              type="password"
              onBlur={(e) => {
                trigger("confirmPassword");
              }}
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder={t("login.confirmPassword")}
              errors={errors.confirmPassword}
              rules={{
                required: t("errors.required"),
                validate: (value: any) =>
                  value === watch("password") || "Passwords do not match",
              }}
            />
          </Col>
          <Col md={8} sm={12} xs={12}>
            <Button
              className="d-none d-md-block"
              title="payments.continue"
              size="sm"
              style={{
                width: "100%",
                borderRadius: "8px",
                fontWeight: "700",
                fontSize: "14px",
              }}
              // Trigger validation on the current step
              onClick={handleSubmit(onSubmit)}
            />
          </Col>
        </Row>
        <div
          className="d-block d-md-none"
          style={{
            position: "fixed",
            borderTop: "0.5px solid",
            borderTopColor: theme.colors.grey.dark,
            zIndex: 100,
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: theme.colors.grey.darker,
            textAlign: "center",
            padding: "10px",
          }}
        >
          <Button
            title="payments.continue"
            style={{
              width: "100%",
              borderRadius: "8px",
              fontWeight: "700",
              fontSize: "14px",
            }}
            // Trigger validation on the current step
            onClick={handleSubmit(onSubmit)}
            isLoading={resetPasswordLoading}
            disabled={resetPasswordLoading}
          />
        </div>
      </Container>
      <CopyModal
        open={resetPasswordSuccess}
        setOpen={() => {}}
        heading="login.passwordRestored"
        actionBtn="signIn.signInButton"
        onActionClick={() => navigate("/login")}
        mainContent={
          <>
            <Heading
              title="login.yourPasswordRestored"
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normal}
            />
          </>
        }
      />
    </div>
  );
};

export default ForgotPasswordSection;
