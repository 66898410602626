import AvailableCredit from "@components/creditCardBox/availableCredit";
import { SelectBox } from "@components/form/selectBox";
import React, { useContext, useEffect } from "react";
import { theme } from "@styles/themes";
import YouPayYouGet from "@components/creditCardBox/youPayYouGet";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { TabContext } from "@context/TabContext";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { GetCreditFormType } from "./types";
import { usePayment } from "@context/PaymentContext";
import { currencies } from "@utils/currency";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { ErrorText } from "../signUpSection/stepFive";
import { useForm as useFormContext } from "@context/FormContext";
import { paymentMethods } from "@utils/payments";
import { toast } from "react-toastify";

const StyledDiv = styled.div`
  background-color: ${theme.colors.grey.dark};
  padding: 24px;
  border-radius: 8px;
  position: sticky;
  top: 40px;

  @media (max-width: 600px) {
    background-color: unset;
    padding: 0;
    border-radius: 0;
    position: static;
    top: auto;
  }
`;

const GetCreditCard = () => {
  // variables
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const {
    isPaymentSuccess,
    currency,
    setCurrency,
    setAmount,
    convertToCredit,
    resetPayment,
    paymentMethod,
  } = usePayment();
  const { activeTab, setActiveTab } = tabContext;
  const { data: paymentSummary } = useGetPaymentSummary();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<GetCreditFormType>({
    defaultValues: {
      you_pay: null,
      you_get: 0,
    },
  });
  const youPay = watch("you_pay");

  const isPaymentPage =
    pathname.includes("/payments/billingdetails") && activeTab == 2;
  const { resetForm } = useFormContext();

  // effects
  useEffect(() => {
    setAmount(Number(youPay));
  }, [youPay]);

  useEffect(() => {
    const isPaymentSummaryPage = pathname === "/payments";

    if (isPaymentSummaryPage) {
      resetForm();
      resetPayment();
      setActiveTab(0);
    }
  }, [pathname]);

  // functions
  const onSubmit = (values: GetCreditFormType) => {
    navigate(isPaymentPage ? "/payments/success" : "billingdetails");
  };

  // returns
  if (isPaymentSuccess) {
    navigate(
      isPaymentPage
        ? paymentMethod === paymentMethods.bankTransfer
          ? "/payments/banktransfer"
          : "/payments/success"
        : "billingdetails"
    );
  }

  return (
    <StyledDiv>
      <AvailableCredit
        credits={paymentSummary?.credits}
        bgColor={isMobile ? theme.colors.grey.dark : theme.colors.grey.darker}
      />
      <div style={{ margin: "24px 0" }}>
        <SelectBox
          assistiveText={`1 ${currency?.toUpperCase()} = ${convertToCredit(
            1
          )} ${t("payments.credits")}`}
          bgColor={isMobile ? theme.colors.grey.dark : theme.colors.grey.darker}
          size="sm"
          options={Object.values(currencies)}
          placeholder={(t("profile.currency"))}
          onChange={setCurrency}
          defaultValue={currency}
        />
      </div>
      <YouPayYouGet
        bgColor={isMobile ? theme.colors.grey.dark : theme.colors.grey.darker}
        control={control}
      />
      {errors.you_pay && <ErrorText>{errors.you_pay.message}</ErrorText>}

      <Button
        style={{ width: "100%", margin: "22px 0 0 0", borderRadius: "8px" }}
        onClick={handleSubmit(onSubmit)}
        disabled={youPay == null || youPay.toString() == "" ? true : false}
        title={
          <Heading
            title={
              isMobile
                ? t("payments.buy")
                : pathname.includes("/payments/billingdetails") &&
                  activeTab == 2
                ? paymentMethod === paymentMethods.bankTransfer
                  ? t("payments.seeBankDetails")
                  : t("payments.pay")
                : t("payments.getCredits")
            }
            fontSize="14px"
            fontWeight="700"
            color="#151E2F"
          />
        }
        size="md"
      />
    </StyledDiv>
  );
};

export default GetCreditCard;
