import { theme } from "@styles/themes";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import downArrow from "@assets/icons/caret.svg";

// Define the styled container for the select box
const SelectContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

// Styled display input for the selected option
const SelectDisplay = styled.div<{
  error?: boolean;
  isOpen: boolean;
  size: string;
  bgColor: string;
}>`
  padding: ${(props) =>
    props.size == "lg" ? "14px" : props.size == "sm" ? "5px" : "10px"};
  border: 1px solid ${(props) => props.bgColor}; // Default border color
  border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background: ${(props) => props.bgColor};
  color: ${theme.colors.white.normal}; // Text color
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  ${(props) =>
    props.error &&
    `
    border-color: ${theme.colors.error}; // Border color when there's an error
    box-shadow: 0 0 0 2px ${theme.colors.red}; // Optional: Add a shadow to indicate error without changing border width
  `}

  &:focus {
    border-color: ${theme.colors.white
      .normalActive}; // Border color when focused
  }
`;

const StyledAssistiveText = styled.span`
  color: ${theme.colors.white.normal}; // Text color
  font-size: 12px;
  font-weight: 400;
`;
// Define the styled label component for the floating effect
const StyledLabel = styled.label<{ active: boolean; bgColor: string }>`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: ${(props) => props.bgColor};
  padding: 0 5px;
  transition: 0.2s ease all;
  color: ${theme.colors.white.dark};
  pointer-events: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${({ active }) =>
    active &&
    `left:10px;
    top: 0px;
    font-size: 10px;
    color: ${theme.colors.white.normal};
  `}
`;

// Define the styled arrow component positioned to the right of the select box with rotation
const StyledArrow = styled.img<{ isOpen: boolean }>`
  transition: transform 0.3s ease; /* Smooth transition for rotation */
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
  width: 16px;
`;

// Styled dropdown options container
const OptionsContainer = styled.ul<{ isOpen: boolean; bgColor: string }>`
  position: absolute;
  width: 100%;
  max-height: ${(props) => (props.isOpen ? "200px" : "0px")};
  top: ${(props) => (props.isOpen ? "100%" : "85%")};
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid ${(props) => props.bgColor};
  border-radius: ${(props) => (props.isOpen ? "0 0 8px 8px" : "8px")};
  background: ${(props) => props.bgColor};
  z-index: ${(props) => (props.isOpen ? "100" : "1")};
  transition: max-height 0.3s ease;
  box-sizing: border-box;

  /* Custom scrollbar styling */
  scrollbar-width: thin;

  scrollbar-color: ${theme.colors.grey.darkActive} ${(props) => props.bgColor};

  /* For Webkit-based browsers like Chrome, Safari, etc. */
  &::-webkit-scrollbar {
    width: 8px;
    background: transparent; /* Remove the scrollbar track background */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Remove track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.bgColor}; /* Thumb color */
    border-radius: 80px; /* Round thumb */
  }

  /* Remove up and down buttons (arrows) on scrollbar */
  &::-webkit-scrollbar-button {
    display: none; /* Hide the arrows */
    height: 0;
    width: 0;
  }
`;

// Styled individual option
const OptionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  color: ${theme.colors.white.normal};
  display: flex; // Use flexbox to align the image and text
  align-items: center; // Vertically center the items

  img {
    margin-right: 8px; // Add spacing between the image and the label
    width: 24px; // Set a fixed width for the image
    height: 18px; // Set a fixed height for the image
    object-fit: contain; // Ensure the image keeps its aspect ratio
  }

  &:hover {
    background-color: ${theme.colors.grey.darkActive};
  }
`;

// Define a container for the error message
const ErrorMessage = styled.div`
  color: ${theme.colors.red.normal};
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;
`;

// Define the type for options
interface Option {
  value: string;
  label: string;
  flagSrc?: any;
}

// Define the props for SelectBox component
interface SelectBoxProps {
  options: Option[];
  placeholder?: string;
  size: string;
  bgColor: string;
  assistiveText?: any;
  onChange?: (value: string) => void;
  defaultValue?: string;
  style?: any;
  required?: boolean;
}

// Custom SelectBox component
export const SelectBox: React.FC<SelectBoxProps> = ({
  options,
  placeholder,
  size = "md",
  bgColor,
  assistiveText,
  onChange,
  defaultValue,
  required=false,
  style,
}) => {
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleSelect(defaultValue);
  }, [defaultValue]);

  // Handle select change
  const handleSelect = (optionValue: string) => {
    onChange && onChange(optionValue);
    setValue(optionValue);
    setIsOpen(false);
    setError(!optionValue);
  };

  // Toggle dropdown on click
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ ...style }}>
      <SelectContainer ref={selectRef}>
        <StyledLabel active={true} bgColor={bgColor}>
          {placeholder}
        </StyledLabel>
        <SelectDisplay
          error={required}
          onClick={toggleDropdown}
          isOpen={isOpen}
          size={size}
          bgColor={bgColor}
        >
          {value ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: options.find((option) => option.value === value)
                    ?.flagSrc
                    ? "0px"
                    : "8px",
                }}
              >
                {options.find((option) => option.value === value)?.flagSrc && (
                  <img
                    src={
                      options.find((option) => option.value === value)?.flagSrc
                    }
                    alt="selected-flag"
                    style={{
                      marginRight: "8px",
                      width: "24px",
                      height: "18px",
                      objectFit: "contain",
                    }}
                  />
                )}
                {options.find((option) => option.value === value)?.label}
              </div>
            </>
          ) : (
            placeholder
          )}
          <StyledArrow src={downArrow} alt="arrow" isOpen={isOpen} />
        </SelectDisplay>
        <OptionsContainer isOpen={isOpen} bgColor={bgColor}>
          {options.map((option) => (
            <div key={option.value}>
              <OptionItem onClick={() => handleSelect(option.value)}>
                {option?.flagSrc && <img src={option.flagSrc} />}
                {option.label}
              </OptionItem>
            </div>
          ))}
        </OptionsContainer>
      </SelectContainer>
      {required && <ErrorMessage>This field is required</ErrorMessage>}
      {assistiveText && (
        <StyledAssistiveText>{assistiveText}</StyledAssistiveText>
      )}
    </div>
  );
};
