import moment from "moment";
import { SchedulerType } from "@api/types";
import { APP_AUTH_TIME } from "./keys";

export const convertTo12HourFormat = (time24: string) => {
  const [hours, minutes] = time24.split(":");
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${formattedHour}:${minutes} ${period}`;
};

export const convertTo24HourFormat = (time12h: string) => {
  // Split the time string into time and period (AM/PM)
  const [time, period] = time12h.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  // Convert to 24-hour format
  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  // Format hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Return the 24-hour formatted time string
  return `${formattedHours}:${formattedMinutes}`;
};

export const specialTimestampsToTimeStrings = (
  timestampsArray: SchedulerType[] | string
): string[][] => {
  if (timestampsArray === undefined || typeof timestampsArray === "string") {
    return [[], []];
  }

  let outputTime = timestampsArray.map((interval) => {
    if (Object.keys(interval).length === 0) {
      return [];
    }

    const formatTime = (seconds: number) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return convertTo12HourFormat(
        `${hours?.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`
      );
    };

    let startSeconds =
      interval.turnOnTime - new Date().getTimezoneOffset() * 60;
    let endSeconds = interval.turnOffTime - new Date().getTimezoneOffset() * 60;
    if (startSeconds > 86399) {
      startSeconds = startSeconds - 86400;
    } else if (endSeconds > 86399) {
      endSeconds = endSeconds - 86400;
    }
    const start = formatTime(startSeconds);
    const end = formatTime(endSeconds);
    return [start, end];
  });

  if (outputTime.length == 1) {
    return [outputTime[0], []];
  } else if (outputTime.length == 2) {
    return outputTime;
  } else {
    return [[], []];
  }
};

export const timeStringsToSpecialTimestamps = (timeStrings) => {
  let filterArray = timeStrings.filter(
    (element) =>
      element !== "" &&
      element !== "undefined - undefined" &&
      element !== "NaN:NaN - NaN:NaN"
  );
  let outputTimeStamp = filterArray.map((timeString) => {
    const [start, end = ""] = timeString.split(" - ");
    if (start == "" && end == "") {
      return;
    }

    // Function to convert time string to seconds
    const timeToSeconds = (time) => {
      let localTime = time;
      if (
        time.toLowerCase().includes("am") ||
        time.toLowerCase().includes("pm")
      ) {
        localTime = convertTo24HourFormat(localTime);
      }
      const [hours, minutes] = localTime.split(":");
      return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60;
    };

    let turnOnTime = timeToSeconds(start) + new Date().getTimezoneOffset() * 60;
    let turnOffTime = timeToSeconds(end) + new Date().getTimezoneOffset() * 60;

    if (turnOnTime < 0) {
      turnOnTime = turnOnTime + 86400;
    }
    if (turnOffTime < 0) {
      turnOffTime = turnOffTime + 86400;
    }

    return {
      turnOnTime,
      turnOffTime,
    };
  });

  if (outputTimeStamp.length == 1) {
    return [outputTimeStamp[0], {}];
  } else if (outputTimeStamp.length == 2) {
    return outputTimeStamp;
  } else {
    return [{}, {}];
  }
};

export const transformToDate = (timestamp: number | undefined) => {
  if (timestamp === undefined) {
    return "00/00/0000";
  }

  const date = new Date(timestamp);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear()?.toString().slice(-2);
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const isUserAuthExpiring = () => {
  // Calculate the difference in milliseconds
  let startTimestamp = parseInt(localStorage.getItem(APP_AUTH_TIME));

  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - startTimestamp;

  // Convert milliseconds to minutes (divide by 1000 * 60)
  const minutesPassed = Math.floor(timeDifference / (1000 * 60));

  // Check if 45 minutes have passed
  return minutesPassed >= 45;
};

export const generateTimeArray = () => {
  const times = [];
  let current = moment().startOf("day"); // Start from 00:00

  while (current.format("HH:mm") !== "00:00" || times.length === 0) {
    times.push(current.format("hh:mm A"));
    current.add(5, "minutes");
  }

  return times;
};

export const DATE_FORMATS = ["DD/MM/YYYY", "MM/DD/YYYY"];
