import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext, useEffect, useRef } from "react";
import { Button } from "@components/button/button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { useGetProfiles } from "@api/useGetProfiles";
import { useState } from "react";
import { TabContext } from "@context/TabContext";
import { useAuth } from "@context/AuthContext";
import { useGetNotifications } from "@api/useGetNotifications";
import Head from "./myDataSection/head";
import { useTranslation } from "react-i18next";

const TabContainer = styled.div`
  display: flex;
  gap: 6px;
  background-color: ${theme.colors.grey.darker};
  border-radius: 30px;
  padding: 4px;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 0px 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${(props) =>
    props.active ? theme.colors.grey.dark : "transparent"};
  color: ${(props) =>
    props.active ? theme.colors.white.normal : theme.colors.white.normalActive};
  font-size: 12px;
`;

const DesktopProfileHead = ({ setSettingOpen }) => {
  const { t } = useTranslation();
  const { data: notificationsData } = useGetNotifications();
  const { convertToAppCurrency, accountDetail } = useAuth();
  const navigate = useNavigate();
  const { notificationCardOpen, setNotificationCardOpen } =
    useContext(TabContext);
  const [dropDown, setDropDown] = React.useState<boolean>(false);
  const notificationIsOpenRef = useRef<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      notificationIsOpenRef.current = notificationCardOpen;
    }, 100);
  }, [notificationCardOpen]);

  const { data: paymentSummary } = useGetPaymentSummary();
  const credits = paymentSummary?.credits;

  const { data: profiles } = useGetProfiles();
  const profit = profiles?.profits;
  const profitDiff = profiles?.profitsDif;
  const [activeTab, setActiveTab] = useState(0);

  const handleDropDown = () => {
    if (notificationCardOpen) {
      setNotificationCardOpen(false);
      setDropDown(true);
    } else if (dropDown) {
      setDropDown(false);
    } else {
      setDropDown(true);
      setNotificationCardOpen(false);
    }
  };

  // FILTER PROFIT DIFF
  const filteredProfitDif =
    activeTab === 0
      ? null
      : activeTab === 1
      ? profitDiff.oneDay
      : activeTab === 2
      ? profitDiff?.oneWeek
      : profitDiff?.oneMonth;
  return (
    <div className="mb-4">
      <Head title="profile.profile" backBtn={false} />
      <div className="d-flex  align-items-center  flex-wrap">
        <div
          className="d-flex justify-content-between align-items-center mt-3 me-4"
          style={{
            backgroundColor: theme.colors.grey.dark,
            borderRadius: "8px",
            padding: "10px 16px",
            width: "max-content",
            minWidth: "348px",
            height: "96px",
          }}
        >
          <div>
            <div
              style={{ marginBottom: "8px" }}
              className="d-flex align-items-center justify-content-between"
            >
              <div style={{ marginRight: "33px" }}>
                <Heading
                  title="profile.profit"
                  fontSize="16px"
                  fontWeight="400"
                  color={theme.colors.white.normalActive}
                />
              </div>

              <TabContainer>
                {[t("dashboard.all"), t("dashboard.oneday"), t("dashboard.oneweek"), t("dashboard.onemonth")].map((tab, index) => (
                  <Tab
                    key={tab}
                    active={activeTab === index}
                    onClick={() => setActiveTab(index)}
                  >
                    <Heading
                      title={tab}
                      fontSize="12px"
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </Tab>
                ))}
              </TabContainer>
            </div>
            <div className="d-flex">
              {profit && (
                <Heading
                  title={convertToAppCurrency(
                    activeTab == 0
                      ? profit?.all
                      : activeTab == 3
                      ? profit?.oneMonth
                      : activeTab == 1
                      ? profit?.oneDay
                      : activeTab == 2
                      ? profit?.oneWeek
                      : profit?.all || 0
                  )}
                  fontSize="24px"
                  fontWeight="700"
                  color={theme.colors.white.normal}
                />
              )}
              {filteredProfitDif != null && (
                <div
                  style={{
                    // backgroundColor: theme.colors.grey.darker,
                    borderRadius: "16px",
                    marginLeft: "8px",
                    // padding: "2px 12px",
                  }}
                >
                  <Heading
                    title={
                      filteredProfitDif < 0
                        ? `${filteredProfitDif.toFixed(2)}%`
                        : `+${filteredProfitDif.toFixed(2)}%`
                    }
                    fontSize="14px"
                    fontWeight="700"
                    color={
                      filteredProfitDif < 0
                        ? theme.colors.red.normal
                        : theme.colors.green.normal
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center mt-3"
          style={{
            backgroundColor: theme.colors.grey.dark,
            borderRadius: "8px",
            padding: "10px 16px",
            width: "max-content",
            minWidth: "348px",
            height: "96px",
          }}
        >
          <div>
            <div style={{ marginBottom: "8px" }}>
              <Heading
                title="profile.availableCredit"
                fontSize="16px"
                fontWeight="400"
                color={theme.colors.white.normalActive}
              />
            </div>
            <Heading
              title={credits?.toString()}
              fontSize="24px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
          </div>
          <Button
            title="payments.getCredits"
            style={{
              fontWeight: "700",
              fontSize: "14px",
              borderRadius: "8px",
              color: theme.colors.grey.darker,
            }}
            onClick={() => navigate("/payments")}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopProfileHead;
