import Heading from "@components/font/heading";
import { useStatsChart } from "@context/StatsChartContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";

const BetsChartFilter = ({ setOpenFilter, openFilter }) => {
  const { clearFilters, getActiveFilters } = useStatsChart();

  const handleBetsFilterClear = () => {
    clearFilters("bets");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 12,
      }}
    >
      {(getActiveFilters("bets").sports.length > 0 ||
        getActiveFilters("bets").profiles.length > 0 ||
        getActiveFilters("bets").bookies.length > 0) && (
        <Heading
          title="profile.cleanFilters"
          fontSize="14px"
          fontWeight="400"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          color={theme.colors.white.normal}
          onClick={handleBetsFilterClear}
        />
      )}
      <div style={{ position: "relative" }}>
        <Icon
          icon="charm:filter"
          color={theme.colors.white.normal}
          width={20}
          height={20}
          onClick={() => setOpenFilter(!openFilter)}
        />
        {(getActiveFilters("bets").sports.length > 0 ||
          getActiveFilters("bets").profiles.length > 0 ||
          getActiveFilters("bets").bookies.length > 0) && (
          <div
            style={{
              width: "8px",
              height: "8px",
              background: theme.colors.red.normal,
              borderRadius: "50%",
              position: "absolute",
              top: 2,
              right: 0,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BetsChartFilter;
