import eu from "@assets/flags/eu.svg";
import us from "@assets/flags/us.svg";

export type AppCurrency = keyof typeof currencies;

export const currencies = {
  eur: {
    value: "eur",
    label: "Euro",
    flagSrc: eu,
  },
  usd: {
    value: "usd",
    label: "Dollar",
    flagSrc: us,
  },
};

export const currenyMultipliers = {
  eur: 2.5,
  usd: 2,
};

export const currencyPaymentCode = {
  eur: 978,
  usd: 840,
};

export const currencyToLocaleMap = {
  eur: "de-DE", // Germany
  usd: "en-US", // United States
};

export const convertToCurrencyAmount = (
  amount: number,
  currency: keyof typeof currencies
) => {
  if (!currency) {
    return amount ? amount?.toString() : null;
  }

  return new Intl.NumberFormat(currencyToLocaleMap[currency], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  }).format(amount);
};
