import React, { createContext, useContext, useState } from "react";
import { AddAddressRequest } from "@api/useAddAddress";

interface FormContextType {
  formData: AddAddressRequest | null;
  setFormData: (data: AddAddressRequest | null) => void;
  resetForm: () => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [formData, setFormData] = useState<AddAddressRequest | null>(null);

  const resetForm = () => {
    setFormData(null);
  };

  return (
    <FormContext.Provider value={{ formData, setFormData, resetForm }}>
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
};
